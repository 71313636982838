import {createReducer} from "libs/createReducer";

const initialState = {
    changingAvatar: false,
    newAvatar: {
        icon: undefined,
        color: undefined,
    }
};

export const deadlastReducer = createReducer({
    initialState,
    immerReducers: {
        DEADLAST_AVATAR_CHANGE(state, {currentAvatar}) {
            state.changingAvatar = true;
            state.newAvatar = currentAvatar || {};
        },
        DEADLAST_AVATAR_SET_ICON(state, {icon}) {
            state.newAvatar.icon = icon;
        },
        DEADLAST_AVATAR_SET_COLOR(state, {color}) {
            state.newAvatar.color = color;
        },
        DEADLAST_AVATAR_DONE(state) {
            state.changingAvatar = false;
        },

    },
    reducers: {
        LEAVE: () => initialState,
    }
});