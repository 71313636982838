import React from 'react';
import {HelpSection} from "../HelpSection";
import {HelpLink} from "../HelpLink";
import {Tip} from "../../general/Tip";
import {Text} from "../../../styled/Typography";
import {GAMES} from "shared/constants/games";

export const CluelessArtistHelp = () =>
    <HelpSection title="Clueless Artist" link={GAMES.CLUELESS_ARTIST} tableOfContents>
        <HelpSection title="Overview">
            <p>
                A game of Clueless Artist is made up of several short rounds. In each round the players will be set with
                the task of drawing a subject one line at a time. One player each round is randomly chosen to be the
                "Clueless Artist" – this player does not get to know what subject they are drawing. The Clueless Artist
                is instead given a vague category that the subject falls within. The Clueless Artist must observe the
                other players actions carefully and try to identify the subject, while not giving themselves away. Only
                through deceit and a little luck will you prevail as the Clueless Artist!
            </p>
        </HelpSection>

        <HelpSection title="Game Flow">
            <p>
                Each game of Clueless Artist consists of several rounds, and each round in a game of Clueless Artist
                consists of several sequential phases. These phases are described below.
            </p>
            <Tip>
                Communication is strongly encouraged in all phases of the game!
            </Tip>
            <HelpSection title="Subject Maker chooses a Category and Subject">
                <p>
                    If the <HelpLink>Subject Maker</HelpLink> is enabled in the game
                    configuration, then the player selected as the Subject Maker will come up with a category and a
                    subject that falls within that category. During this time the other players have no responsibility.
                </p>
                <p>
                    If the Subject Maker is not enabled in the game, then the category and subject will be chosen
                    automatically at random – skipping this phase entirely.
                </p>
            </HelpSection>

            <HelpSection title="Players Draw the Subject">
                <p>
                    During this phase players will have several opportunities (2 by default) to add a single line to the
                    picture and in doing so, draw the subject, and prove to the other players that they know what the
                    subject is.
                </p>
                <ul>
                    <li>The Subject Maker does not participate in this phase.</li>
                    <li>The remaining players take turns drawing exactly one line.</li>
                    <li>
                        One random player who is drawing is the Clueless Artist – they do not know the subject. The
                        Clueless Artist can use only their knowledge of the category and the context of what other
                        players have drawn to know what to draw.
                    </li>
                </ul>
                <Tip>
                    When drawing, the line can be as simple or complex as desired, but as soon as you lift the
                    (virtual) pen it is done. You do have the ability to undo if you make a mistake while drawing your
                    line, but that is the extent of your ability to edit what you've drawn.
                </Tip>
            </HelpSection>

            <HelpSection title="Players Vote On Who They Think The Clueless Artist Is">
                <p>
                    Armed with the knowledge of seeing what the other players contributed to the drawing, each player
                    must choose who they think was the Clueless Artist. The Clueless Artist also votes in this phase,
                    and can attempt to persuade players to vote for someone other than themselves.
                </p>
                <Text $bold>
                    The Vote is only successful in finding the Clueless Artist if they receive the most votes. In the
                    event of a tie, the Clueless Artist is considered undetected.
                </Text>
                <div>
                    The game proceeds depending on the result of this vote:
                    <ul>
                        <li>If the Clueless Artist is revealed, then they will have a chance to guess the subject.</li>
                        <li>If the Clueless Artist is not revealed, then the round is over.</li>
                    </ul>
                </div>
            </HelpSection>

            <HelpSection title="The Clueless Artist Tries to Guess the Subject">
                <p>
                    If the Clueless Artist received the most votes in the previous phase, they have a chance to guess
                    the subject. They will gain points if they are correct, and other artists gain points otherwise.
                </p>
            </HelpSection>

            <HelpSection title="The Players Determine if the Clueless Artist's Guess Was Correct">
                <p>
                    In many cases the Clueless Artist will not guess the exact word that is the subject. For example,
                    the Subject Maker might have chosen the subject "child" and then the Clueless Artist might guess
                    "kid". When this happens it is up to the players to determine if they got close enough.
                </p>
                <Tip tipText="Info">
                    <i>
                        <p>
                            Depending on how the game was configured, the Subject Maker and Clueless Artist may be
                            excluded
                            from voting in this phase. This setting can be changed by the Lobby Leader in the Game
                            Configuration.
                        </p>
                        <p>
                            The number of required "Yes" votes is listed before you submit your vote, and the vote will
                            instantly complete if that number of "Yes" votes are received.
                        </p>
                    </i>
                </Tip>
                <Tip>
                    Don't be a jerk. Guess votes decide who gains points, and it ruins the game if they are not done
                    in good faith. If a Clueless Artist guessed something that is clearly very close to the original
                    Subject you should always vote Yes.
                </Tip>
            </HelpSection>

            <HelpSection title="Points are Awarded">
                <div>
                    There are two possible outcomes for each round:

                    <ul>
                        <li>
                            The Clueless Artist is undetected or they guess the subject correctly – The Clueless Artist
                            and Subject Maker (if applicable) both receive two points.
                        </li>
                        <li>
                            The Clueless Artist is detected and does not guess the subject correctly – All players other
                            than the Clueless Artist and the Subject Maker gain a point.
                        </li>
                    </ul>
                </div>
            </HelpSection>

            <HelpSection title="Game End">
                <p>
                    The game continues until a player or players exceed the score limit. At this point all players with
                    the most points win!
                </p>
            </HelpSection>
        </HelpSection>

        <HelpSection title="Setup">
            <HelpSection title="Reserving a Color">
                <p>
                    Each player can choose in advance which color they want to use while drawing. These colors will also
                    be displayed next to your name in the game.
                </p>
            </HelpSection>
            <Text $bold>
                Only the Lobby Leader can control the following settings. Changing these settings can dramatically
                change gameplay!
            </Text>
            <HelpSection title="Subject Maker">
                <p>
                    The option labeled "One player per round is Subject Maker" determines whether or not players will be
                    responsible for coming up with the category and subject each round. When the option is enabled, one
                    player each round will be chosen as the Subject Maker. This player will be asked to come up with a
                    category, and a subject that falls within that category.
                </p>
            </HelpSection>

            <HelpSection title="Choosing Who Votes">
                <p>
                    The option labeled "All players vote when Clueless Artist's guess doesn't match subject exactly"
                    controls who guesses when <HelpLink>The Players Determine if the Clueless Artist's Guess Was
                    Correct</HelpLink>.
                </p>
                <Tip>
                    We recommend leaving this option checked. No matter how the game is configured, the non-clueless
                    artists will always have the final say in determining if the Clueless Artist's guess was correct.
                    Only turn this option off if you find that members of your group are often unfairly trying to award
                    themselves points.
                </Tip>
            </HelpSection>

            <HelpSection title="Number of Lines Each Player Draws">
                <p>
                    This option controls how many times each player will have a chance to draw a line each round.
                </p>
            </HelpSection>

            <HelpSection title="Game Ending Score">
                <p>
                    This option determines how many points are necessary for a player to win.
                </p>
            </HelpSection>
        </HelpSection>

        <HelpSection title="Strategy">
            <HelpSection title="As the Subject Maker">
                <p>
                    The Subject Maker only gains points when the Clueless Artist wins a round. For this reason it is
                    better to create a subject that is not too difficult to figure out. A subject like "existential
                    dread" is funny, but virtually impossible to draw. Instead try simpler things that all players will
                    know and be able to draw. Remember, it's a game - Try to find the balance between overly easy and
                    fun/interesting subjects.
                </p>
            </HelpSection>
            <HelpSection title="As the Clueless Artist">
                <p>
                    Drawing something is difficult when you don't know what it is, but you can take advantage of the
                    fact that no one wants to completely give away what the subject is by drawing it too accurately. If
                    you have no idea what you're drawing at all (like if you are the first to draw), then try drawing
                    vague/generic shapes. You always have the category to fall back on. For example, if the category is
                    "food", you probably want to stick to roundish shapes.
                </p>
            </HelpSection>
            <HelpSection title="As a Regular Artist">
                <p>
                    Remember that sometimes being too good of an artist is not to your advantage. You are trying to vet
                    yourself to the other players while not giving away the subject to the Clueless Artist. Be careful
                    to not give away extra details when trying to explain your drawings.
                </p>
            </HelpSection>
        </HelpSection>
    </HelpSection>;