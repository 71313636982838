import {createReducer} from "libs/createReducer";
import {applyPatches} from "immer";
import {applyUpdate} from "../libs/applyUpdate";

const initialState = {
    byId: {},
    order: [],
};

export const playersReducer = createReducer({
    initialState,
    reducers: {
        SERVER_UPDATE(state, {players}) {
            if (players) {
                return Array.isArray(players)
                    ? applyPatches(state, players)
                    : Object.assign({}, state, players)
            }
            return state;
        },
        LOCAL_UPDATE(state, {players}) {
            return players ? applyUpdate(state, players) : state;
        },
        LEAVE: () => initialState,
    },
    immerReducers: {
        LOCAL_CHANGE_PLAYER_ORDER: (state, {playerOrder}) => {
            state.order = playerOrder
        }
    },
});