import React from 'react';
import {useField} from "./useField";
import {GroupedCheckbox} from "./GroupedCheckbox";

export function FormikGroupedCheckbox({value, ...props}) {
    const {field, helper} = useField(props);
    return <GroupedCheckbox
        value={value}
        selected={field.value}
        onChange={helper.setValue}
        {...props}
    />;
}