import styled, {css} from "styled-components/macro";
import {clickable, fontProps} from "./mixins";

export const Heading = styled.h1`
    font-size: 3rem;
    color: #363636;
    text-align: center;
`;

export const SecondHeading = styled.h2`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
`;

export const SubHeading = styled.h3`
    font-size: 1.25rem;
    text-align: center;
    font-weight: bold;
`;

export const PageHeading = styled(Heading)`
    font-family: "Permanent Marker", sans-serif;
    line-height: 1;
    margin-top: 0.25rem; 
    @media (min-width: 350px) {
        margin-top: 0.75rem; 
    }
    @media (min-width: 700px) {
        margin-top: 1.25rem; 
    }
    @media (min-width: 1024px) {
        margin-top: 1.75rem; 
    }
`;

export const FakeLink = styled.span`
    ${({disabled}) => !disabled && css`
        color: ${({theme}) => theme.colors.link};
        ${clickable}
    `}
    ${fontProps}
`;

export const Text = styled.p`
    ${fontProps};
`;

export const InlineText = styled.span`
    ${fontProps};
`;