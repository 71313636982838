import {startWaiting} from "../actions/play";
import {getGame, getSocket} from "../selectors/handshakeSelectors";

const emitAndWait = (event, ...args) => (dispatch, getState) => {
    dispatch(startWaiting());
    getSocket(getState()).emit(event, ...args);
};

export const gameAction = (action, ...args) => (dispatch, getState) => {
    const state = getState();
    dispatch(startWaiting());
    getSocket(state).emit(getGame(state), action, ...args);
}

export default emitAndWait;