import {createReducer} from "libs/createReducer";

const initialState = {
    socket: undefined,
    loading: true,
    waiting: false,
    reconnecting: false,
    game: undefined,
    lastUpdateId: undefined,
    waitingTimer: undefined,
};

export const handshakeReducer = createReducer({
    initialState,
    immerReducers: {
        NEW_SOCKET(state, {socket}) {
            state.socket = socket;
        },
        SERVER_UPDATE(state, {handshake, id, isInitial}) {
            state.loading = false;
            state.waiting = false;
            state.reconnecting = false;
            if (state.waitingTimer) {
                clearTimeout(state.waitingTimer);
                state.waitingTimer = undefined;
            }
            if (handshake) {
                state.game = handshake.game;
            }
            if (!isInitial && id !== state.lastUpdateId + 1) {
                state.reconnecting = true;
                state.socket.emit('handshake-needs-initial');
            } else {
                state.lastUpdateId = id;
            }
        },
        SERVER_NOOP(state) {
            state.waiting = false;
        },
        START_WAITING(state, {timer}) {
            state.waiting = true;
            state.waitingTimer = timer;
        },
        DISCONNECTED(state) {
            state.reconnecting = true
        },
    },
    reducers: {
        LEAVE: () => initialState,
    }
});