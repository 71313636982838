import React from 'react';

export const SubmitButton = ({disabled, children = "Submit", ...rest}) => (
    <div className="field">
        <div className="control">
            <button type="submit"
                    className="button is-primary is-fullwidth"
                    disabled={disabled}
                    {...rest}
            >
                {children}
            </button>
        </div>
    </div>
);