import React from 'react';
import {useSelector} from "react-redux";
import {PlayerList} from "./PlayerList/PlayerList";
import PropTypes from 'prop-types';
import {getPlayerLists} from "selectors/playersSelectors";

export function PlayerLists({reorderable}) {
    const {activePlayers, waitingPlayers, spectatingPlayers} = useSelector(getPlayerLists);

    return <>
        {activePlayers.length > 0 && <div>
            <p className="has-text-weight-bold">Players</p>
            <PlayerList players={activePlayers} listId="active" reorderable={reorderable}/>
        </div>}

        {waitingPlayers.length > 0 && <div>
            <p className="has-text-weight-bold">Waiting to Join</p>
            <PlayerList players={waitingPlayers} listId="waiting" reorderable={false}/>
        </div>}

        {spectatingPlayers.length > 0 && <div>
            <p className="has-text-weight-bold">Spectators</p>
            <PlayerList players={spectatingPlayers} listId="spectating" reorderable={false}/>
        </div>}
    </>
}

PlayerLists.propTypes = {
    reorderable: PropTypes.bool.isRequired,
};