import React, {useEffect} from 'react';
import {Layout} from "components/layout/Layout";
import {useLocation} from "react-router-dom";
import {ScrollToTop} from "../general/ScrollToTop";
import {Help} from "../help/Help";
import {PageHeading} from "../../styled/Typography";

export const HelpPage = () => {
    const location = useLocation();

    useEffect(() => {
        const {hash} = location;
        if (hash) {
            const el = document.getElementById(hash.substr(1));
            if (el) {
                el.scrollIntoView();
            }
        }
    }, [location]);

    return <Layout mode="wide" title="Help" renderTitle={false}>
        <PageHeading>Help Topics</PageHeading>
        <ScrollToTop/>
        <Help/>
    </Layout>
};