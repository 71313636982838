import {CustomCell, GridContainer, Row} from "../../general/Grid";
import {Link} from "react-router-dom";
import React from "react";
import {games} from "../../games";

const Lobby = CustomCell({width: '60px'});
const Game = CustomCell({width: '80px'});
const Players = CustomCell({width: '150px', grow: 4, align: "left"});

export const LobbiesList = ({lobbies}) =>
    <GridContainer striped>
        <Row>
            <Lobby.Heading>Lobby</Lobby.Heading>
            <Game.Heading>Game</Game.Heading>
            <Players.Heading>Players</Players.Heading>
        </Row>
        {lobbies.map(({code, game, players}) => (
            <Row key={code}>
                <Lobby.Cell><Link to={`/play/${code}`} className="has-text-weight-bold">{code}</Link></Lobby.Cell>
                <Game.Cell>{games[game].name}</Game.Cell>
                <Players.Cell>{players.map(player => player.name).join(', ')}</Players.Cell>
            </Row>
        ))}
    </GridContainer>