import React, {useMemo} from 'react';
import {GeneralHelp} from "./sections/GeneralHelp";
import {SpyfallHelp} from "./sections/SpyfallHelp";
import {AvalonHelp} from "./sections/AvalonHelp";
import {games} from "../games";
import {GameButton} from "../general/GameButton";
import {createHelpHash} from "./createHelpHash";
import {useNavigate} from "react-router-dom";
import {WrapContainer} from "../../styled/Containers";
import {CluelessArtistHelp} from "./sections/CluelessArtistHelp";
import {HeistHelp} from "./sections/HeistHelp";

export const Help = () => {
    const navigate = useNavigate();
    const topics = useMemo(() => (
        [{
            name: 'General',
            ButtonComponent: (props) => <GameButton {...props}>General</GameButton>
        }].concat(Object.values(games))
    ), []);

    return <>
        <WrapContainer>
            {topics.map(({name, ButtonComponent}) => (
                <ButtonComponent key={name} onClick={() => navigate({hash: '#' + createHelpHash(name)})}/>
            ))}
        </WrapContainer>
        <GeneralHelp/>
        <SpyfallHelp/>
        <AvalonHelp/>
        <CluelessArtistHelp/>
        <HeistHelp/>
    </>
};