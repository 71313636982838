import styled from 'styled-components/macro';

export const ButtonLevel = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.25rem 0;
    margin: 0 -0.25rem;
    
    button {
        flex-grow: 1;
        flex-basis: 100px;
        margin: 0.25rem;
        font-weight: bold;
    }
`;

export const ButtonLevelContainer = styled.div`
    background-color: #efefef;
`;
