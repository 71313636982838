import {createSelector} from "reselect";
import jwtDecode from "jwt-decode";

export const getToken = state => state.user.token;

export const getDecodedToken = createSelector(
    [getToken],
    (token) => {
        try {
            const jwt = jwtDecode(token);
            if (!jwt.exp || jwt.exp > Date.now() / 1000) { // Token is expired
                return jwt;
            }
        } catch {
        }
        return undefined;
    }
);

export const getUser = createSelector(
    [getDecodedToken],
    ({id, name} = {}) => ({id, name})
);

export const getIsAdmin = createSelector(
    [getDecodedToken],
    ({admin} = {}) => admin === true
);