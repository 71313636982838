import React, {useCallback, useState} from 'react';
import {Formik} from "formik";
import * as Yup from "yup";
import {FieldContainer, FormikInput, FormikTextarea} from "../forms/fields";
import {useLocation, useMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import {useMutation} from "@apollo/client";
import {CREATE_FEEDBACK} from "../../graphQL/general";
import {SpaceVertical} from "../../styled/Containers";

export const FeedbackForm = ({children}) => {
    const [sent, setSent] = useState(false);
    const location = useLocation();
    const exportableState = useSelector(state => ({
        handshake: state.handshake,
        lobby: state.lobby,
        players: state.players,
        deadlast: state.deadlast,
        timesyncOffset: state.timesync.offset,
    }));
    const isPlaying = useMatch('/play/*');
    const [createFeedback] = useMutation(CREATE_FEEDBACK);

    const onSubmit = useCallback(async (values, {resetForm}) => {
        await createFeedback({
            variables: {
                feedback: {
                    ...values,
                    location,
                    ...(isPlaying && {state: exportableState}),
                }
            }
        });

        resetForm();
        setSent(true);
    }, [location, isPlaying, exportableState, createFeedback]);

    return <Formik
        initialValues={{name: '', email: '', feedback: ''}}
        validationSchema={Yup.object().shape({
            email: Yup.string().email('Email is optional, but if you enter one it must be valid'),
            feedback: Yup.string().required('You must enter feedback')
        })}
        onSubmit={onSubmit}
    >
        {({isSubmitting, errors}) =>
            children({
                sent,
                form: <SpaceVertical>
                    <FieldContainer name="name" label="Name"><FormikInput/></FieldContainer>
                    <FieldContainer name="email" label="Email"><FormikInput/></FieldContainer>
                    <FieldContainer name="feedback" label="Feedback"><FormikTextarea/></FieldContainer>

                    <SpaceVertical className="is-size-7">
                        <p>
                            Name and email are only used to contact you if I have follow up questions, and is totally
                            optional.
                        </p>
                        <p>
                            {isPlaying
                                ?
                                <b>You are currently in a game, so your game state will be sent with your feedback.</b>
                                : <>
                                    You are not currently in a lobby, so I will only receive your feedback. If you're
                                    having an issue with a game, it's very helpful if you submit feedback just as the
                                    issue is happening. By doing this your feedback will contain information about what
                                    is happening in your game that helps me squish bugs!
                                </>
                            }
                        </p>
                        <p>
                            If your feedback helps me make changes and you include a name, I will give you a shout-out
                            on the Changes page. Don't worry, I won't include any personally identifiable information.
                            If you don't want credit just say so in your feedback.
                        </p>
                    </SpaceVertical>
                </SpaceVertical>,
                submit: <button
                    type="submit"
                    className="button is-success is-fullwidth"
                    disabled={isSubmitting || Object.keys(errors).length}
                >
                    Send Feedback
                </button>,
            })
        }
    </Formik>;
};