import {combineReducers} from 'redux'
import {handshakeReducer} from './handshakeReducer';
import {lobbyReducer} from './lobbyReducer'
import {playersReducer} from './playersReducer';
import {timesyncReducer} from "./timesyncReducer";
import {deadlastReducer} from "components/games/DeadLast/reducer";
import {userReducer} from "./userReducer";
import {cluelessArtistReducer} from "../components/games/CluelessArtist/cluelessArtistReducer";

export default combineReducers({
    handshake: handshakeReducer,
    lobby: lobbyReducer,
    players: playersReducer,
    deadlast: deadlastReducer,
    timesync: timesyncReducer,
    user: userReducer,
    cluelessArtist: cluelessArtistReducer,
});