import {createReducer} from "libs/createReducer";
import {applyUpdate} from "../libs/applyUpdate";

const initialState = {
    spectateOnCooldown: false,

    me: null, // Must not be undefined or briefly host === me
    started: undefined,
    host: undefined,
    state: undefined,
    config: undefined,

    messages: {
        danger: [],
        warning: [],
        success: [],
        info: [],
        primary: [],
    },
};

export const lobbyReducer = createReducer({
    initialState,
    reducers: {
        SERVER_UPDATE(state, {lobby}) {
            return lobby ? applyUpdate(state, lobby) : state;
        },
        LOCAL_UPDATE(state, {lobby}) {
            return lobby ? applyUpdate(state, lobby) : state;
        },
        LEAVE: () => initialState,
    },
    immerReducers: {
        LOBBY_TOGGLE_SPECTATE(state) {
            state.spectateOnCooldown = true
        },
        LOBBY_SPECTATE_COOLDOWN_DONE(state) {
            state.spectateOnCooldown = false
        },
        LOBBY_PROMOTE_PLAYER(state) {
            state.messages.info = []
        },
        LOBBY_MESSAGE(state, {message, messageType}) {
            state.messages[messageType].push(message)
        },
        LOBBY_CLEAR_MESSAGES(state, {messageType}) {
            state.messages[messageType] = []
        },
    }
});