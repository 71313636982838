import {startWaiting} from "./play";
import emitAndWait from "../libs/emitAndWait";
import {getSocket, getWaiting} from "../selectors/handshakeSelectors";

export const localUpdate = (namespacedChanges) => ({
    type: 'LOCAL_UPDATE',
    ...namespacedChanges
});
export const kickPlayer = player => (dispatch, getState) => {
    const state = getState();
    if (
        !getWaiting(state) &&
        window.confirm(`Are you sure you want to kick ${player.name}?`)
    ) {
        dispatch(startWaiting());
        getSocket(state).emit('lobby-kick', player.id);
    }
};
export const leaveLobby = () => emitAndWait('lobby-leave');
export const toggleSpectating = () => (dispatch, getState) => {
    if (getState().lobby.spectateOnCooldown) {
        return;
    }
    getSocket(getState()).emit('lobby-spectating');
    dispatch({type: 'LOBBY_TOGGLE_SPECTATE'});
    setTimeout(() => {
        dispatch({type: 'LOBBY_SPECTATE_COOLDOWN_DONE'});
    }, 1500);
};

export const promotePlayer = player => (dispatch, getState) => {
    const state = getState();
    if (
        !getWaiting(state) &&
        window.confirm(`Are you sure you want to promote ${player.name} to lobby leader?`)
    ) {
        dispatch({type: 'LOBBY_PROMOTE_PLAYER'});
        getSocket(state).emit('lobby-promote', player.id);
    }
};
export const changePlayerOrder = (players) => (dispatch, getState) => {
    const playerOrder = players.map(player => player.id);
    dispatch({type: 'LOCAL_CHANGE_PLAYER_ORDER', playerOrder});
    getSocket(getState()).emit('lobby-player-order', playerOrder);
};
export const requestRejoin = () => emitAndWait('lobby-rejoin-request');
export const respondRejoinRequest = (playerId, approved) => emitAndWait(
    'lobby-respond-rejoin-request',
    {playerId, approved}
);
export const clearMessages = (messageType) => ({type: 'LOBBY_CLEAR_MESSAGES', messageType});