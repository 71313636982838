import React from 'react';
import {useField} from "formik";
import cn from 'classnames';

export const ContainerContext = React.createContext({});

export function FieldContainer(
    {
        name,
        label,
        className,
        children,
    }
) {
    const [, meta] = useField(name);

    return <div className={cn('field', className)}>
        {label && <label className="label">{label}</label>}
        <ContainerContext.Provider value={{name}}>
            {children}
        </ContainerContext.Provider>
        {meta.touched && meta.error && (
            <div className="help is-danger">{meta.error}</div>
        )}
    </div>;
}