import styled from "styled-components/macro";

export const Content = styled.div`
    margin: 0 auto;
    flex: none;
    padding: 0 1.5rem;
    
    @media (max-width: 425px) {
        padding: 0 0.75rem;
    }
    
    @media (max-width: 320px) {
        padding: 0 0.5rem;
    }
`