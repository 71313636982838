import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from "apollo-link-context";

const authLink = setContext((_, {headers}) => {
    const token = localStorage.getItem('token');
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        }
    }
});

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(createHttpLink({uri: '/api'})),
});