import React from 'react';
import styled from 'styled-components/macro';
import {createTeleporter} from "react-teleporter";

const FooterContainer = styled.div`
    width: 100%;
    background-color: #363636;
    color: #fff;
    flex-shrink: 0;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    a {
        color: #d5e4ff;
    }
`

const FooterContent = styled.div`
    margin: 0.75rem 1rem;
    font-size: 0.75rem;
    text-align: center;
`

const FooterTeleporter = createTeleporter();
export const ExtraFooter = FooterTeleporter.Source;

export const Footer = () =>
    <FooterContainer>
        <FooterContent>
            <a
                target="_blank"
                rel="noopener noreferrer"
                className="has-text-weight-bold"
                href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=6WJRE8ZUBX8LE&source=url"
            >
                Consider donating to keep the servers running
            </a>
            <br/>
            Devious Games by&nbsp;
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/trappar"
            >
                Trappar
            </a>
            &nbsp;&copy; {(new Date().getFullYear())}
            <FooterTeleporter.Target/>
        </FooterContent>
    </FooterContainer>;