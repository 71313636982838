import React, {useCallback, useEffect, useMemo, useState} from 'react';
import dayjs from "dayjs";
import styles from './Transition.module.scss';
import {useNavigate} from "react-router-dom";

export const Transition = (
    {
        time = 10,
        title = 'Whoops',
        reason,
        titleClassName,
        to = '/',
        reload = false,
    }
) => {
    const end = useMemo(() => dayjs().add(time, 'seconds'), [time]);
    const [remainingTime, setRemainingTime] = useState(time.toFixed(1));
    const navigate = useNavigate();

    const redirect = useCallback(() => {
        if (reload) {
            window.location.replace(to);
        } else {
            // noinspection JSIgnoredPromiseFromCall
            navigate(to, {replace: true});
        }
    }, [reload, navigate, to]);

    useEffect(() => {
        const timer = setInterval(() => {
            const remainingTime = (dayjs(end).diff(dayjs()) / 1000).toFixed(1);
            setRemainingTime(remainingTime);
            if (remainingTime <= 0) {
                redirect();
            }
        }, 50);

        return () => clearTimeout(timer);
    }, [end, redirect]);

    return <div className={styles.transition}>
        <h1 className={`title ${titleClassName}`}>{title}</h1>
        {reason && <p>{reason}</p>}
        <p>Redirecting in {remainingTime} seconds.</p>
        <span onClick={redirect} className="link">Click here to redirect immediately</span>
    </div>;
};