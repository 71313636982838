import React from 'react';
import {Checkbox} from "./Checkbox";
import {useField} from "./useField";

export function FormikCheckbox(
    {
        trueValue = true, falseValue = false,
        onChange,
        ...props
    }
) {
    const {field, meta, helper} = useField(props);
    return <Checkbox
        {...field}
        {...props}
        checked={field.value === trueValue}
        onChange={e => {
            const newValue = e.currentTarget.checked
                ? trueValue
                : falseValue;
            if (onChange) {
                onChange({field, meta, helper, value: newValue})
            } else {
                helper.setValue(newValue);
            }
        }}
    />
}