import React from 'react';
import {StandardContent} from "./StandardContent";
import {WideContent} from "./WideContent";
import {useDocumentTitle} from "./useDocumentTitle";
import {Content} from "./Content";
import {PageHeading} from "../../styled/Typography";
import {SpaceVertical} from "../../styled/Containers";

export const Layout = React.memo((
    {
        children,
        title,
        renderTitle = true,
        mode = "standard",
    }
) => {
    useDocumentTitle(title);

    let ContainerComponent;
    switch (mode) {
        case 'standard':
            ContainerComponent = StandardContent;
            break;
        case 'wide':
            ContainerComponent = WideContent;
            break;
        default:
            ContainerComponent = Content;
    }

    return <ContainerComponent>
        <SpaceVertical>
            {title && renderTitle && <PageHeading>{title}</PageHeading>}
            {children}
        </SpaceVertical>
    </ContainerComponent>;
});