import React from "react";
import {FeedbackForm} from "./FeedbackForm";
import ReactDOM from 'react-dom';
import {Form} from "formik";

export const FeedbackModal = ({onClose}) => {
    return ReactDOM.createPortal(
        <div className="modal is-active">
            <div className="modal-background" onClick={onClose}/>
            <FeedbackForm>
                {({form, submit, sent}) =>
                    sent
                        ? (
                            <div className="modal-content subtitle">
                                <div className="notification is-success">
                                    <button className="delete" aria-label="close" onClick={onClose}/>
                                    Feedback Sent. Thanks!
                                </div>
                            </div>
                        ) : (<div className="modal-card" style={{overflow: 'auto'}}>
                                <header className="modal-card-head">
                                    <p className="modal-card-title">Feedback</p>
                                    <button className="delete" aria-label="close" onClick={onClose}/>
                                </header>

                                <Form>
                                    <section className="modal-card-body">
                                        {form}
                                    </section>
                                    {!sent && (
                                        <footer className="modal-card-foot">
                                            {submit}
                                        </footer>
                                    )}
                                </Form>
                            </div>
                        )
                }
            </FeedbackForm>
        </div>,
        document.getElementById('modal')
    )
};

/*

 */