import {Icon} from "../general/Icon";
import styled from 'styled-components/macro';

export const CrownIcon = styled(Icon).attrs({icon: "crown"})`
    color: #ffc53d
`;

export const DisconectedIcon = styled(Icon).attrs({icon: "unlink"})`
    color: #d52626;
`

export const PromoteIcon = styled(Icon).attrs({icon: "angle-double-up"})`
    color: #ffc53d
`;

export const KickIcon = styled(Icon).attrs({icon: "times"})`
    color: #d52626;
`