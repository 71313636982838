import styled from "styled-components/macro";
import {Content} from "./Content";

export const WideContent = styled(Content)`
    @media (max-width: 700px) {
        width: 100%;
    }
    @media (min-width: 701px) {
        width: calc(66vw + 233px);
    }
`;