import * as Yup from "yup";
import {Form, Formik} from "formik";
import {FieldContainer, FormikInput, SubmitButton} from "../../forms/fields";
import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";

const validationSchema = Yup.object().shape({
    code: Yup.string()
        .length(5, 'Must be 5 characters'),
});

export function JoinCodeForm() {
    const navigate = useNavigate();

    const visitExistingLobby = useCallback(({code}) => {
        if (code) {
            // noinspection JSIgnoredPromiseFromCall
            navigate(`/play/${code}`);
        }
    }, [navigate]);

    return <Formik
        initialValues={{code: ''}}
        onSubmit={visitExistingLobby}
        validationSchema={validationSchema}
    >
        <Form>
            <FieldContainer name="code">
                <div className="field has-addons">
                    <FormikInput
                        placeholder="Enter a game code here"
                        controlClassName="is-expanded"
                        autoCapitalize="off"
                        autoComplete="off"
                        spellCheck="false"
                        autoCorrect="off"
                    />
                    <div className="control">
                        <SubmitButton>Join</SubmitButton>
                    </div>
                </div>
            </FieldContainer>
        </Form>
    </Formik>
}