import {useMemo} from "react";
import {useLocation} from "react-router-dom";

/**
 * @param defaultRoute
 * @param ignoreRoutes
 * @return {{}}
 */
export const useNextLocation = (defaultRoute, ignoreRoutes = []) => {
    const location = useLocation();

    return useMemo(() => {
        let from = location.state?.from;
        return from && from !== location.pathname && !ignoreRoutes.includes(from)
            ? from
            : defaultRoute;
    }, [location, defaultRoute, ignoreRoutes]);
};