import React, {useCallback, useState} from "react";
import {FeedbackModal} from "./FeedbackModal";

export function Feedback({children}) {
    const [open, setOpen] = useState(false);

    const toggleFeedback = useCallback(
        () => setOpen(open => !open),
        [setOpen]
    );

    return <>
        {children({toggleFeedback})}
        {open && <FeedbackModal open={open} onClose={toggleFeedback}/>}
    </>;
}