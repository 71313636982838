import React from 'react';
import {HelpSection} from "../HelpSection";
import {HelpLink} from "../HelpLink";
import {Tip} from "../../general/Tip";
import {Icon} from "../../general/Icon";
import {GAMES} from "shared/constants/games";

export const AvalonHelp = () =>
    <HelpSection title="Avalon" link={GAMES.AVALON} tableOfContents>
        <HelpSection title="Overview">
            <p>
                Avalon is a game of hidden loyalty. Players are either Loyal Servants of Arthur fighting for goodness
                and honor, or aligned with the evil ways of Mordred. Good wins the game by successfully completing three
                Quests. Evil wins if three quests end in failure. Evil can also win by assassinating Merlin at game's
                end or if a Quest cannot be undertaken.
            </p>

            <p>
                Players may make any claims during the game, at any point in the game. Discussion, deception,
                accusation, and logical deduction are all equally important in order for good to prevail or evil to rule
                the day!
            </p>

            <Tip tipText="note">
                The terms <b>Good</b> and <b>Loyal Servant of Arthur</b> are used interchangeably in Avalon, as
                are <b>Evil</b> and <b>Minion of Mordred</b>. Both refer to the names of the two opposing teams in this
                game.
            </Tip>
        </HelpSection>

        <HelpSection title="Setup">
            <p>
                The number of players present will dictate how many players are assigned to each team. This is visible
                to all players under the <b>TEAMS</b> heading.
            </p>

            <HelpSection title="Player Order">
                <p>
                    If you are the Lobby Leader, then you can reorder players. See the section about{' '}<HelpLink
                    section="general">Reordering Players</HelpLink>.
                </p>
            </HelpSection>

            <HelpSection title="Roles">
                <p>
                    If you are the Lobby Leader, you will see a variety of optional roles which can be enabled or
                    disabled. Adding roles increases the complexity of the game, so we recommend playing with either all
                    roles disabled or "Merlin + Assassin" to start. Each role is displayed with a description next to it
                    which explains how that role affects gameplay.
                </p>
            </HelpSection>
        </HelpSection>

        <HelpSection title="Pre-Game">
            <p>
                Once the game is started, players are given an opportunity to review their assigned team, role (if
                applicable), and any other information they might have access to by clicking
                the <b>Role</b> and <b>Players</b> tabs.
            </p>
        </HelpSection>

        <HelpSection title="Game Flow">
            <p>
                The game consists of several Rounds; each Round has a Team Building phase and a Quest phase. In the Team
                Building phase the Leader proposes a Team to complete a Quest - all players will vote to either approve
                the proposed Team and move on to the Quest phase, or reject the proposed Team, passing leadership to the
                next player and repeating the process until a Team is approved. In the Quest phase those players
                selected to be on the Team will determine if the Quest is successful.
            </p>

            <p>
                Choose your team wisely. Approve only teams where you trust everyone. Even a single evil player on the
                team is enough for failure.
            </p>

            <Tip tipText="note">
                <b>Normally the <Icon icon="crown" className="has-text-warning"/> icon indicates the Lobby Leader, but
                    while in a game of Avalon, it indicates the leader of the current round instead. This is always
                    visible in the Players tab once the game has started.</b>
            </Tip>

            <Tip>
                In every phase of Avalon, tips just like this one are available and can be dismissed by clicking
                {' '}<Icon className="has-text-info" icon="question-circle"/> next to the page title. Often these tips
                will be shown by default. If you're ever confused about what you should be doing, review the tip!
            </Tip>

            <HelpSection title="Team Building Phase">
                <p>
                    After appropriate discussion, the Leader must choose a team consisting of the required number of
                    members. The Leader can be on the team, but is not required to be.
                </p>

                <Tip tipText="strategy">
                    <i>Discuss, discuss, discuss. All the players should participate in helping the Leader make the
                        right choice of players to be on the team. Active and logical discussion is a great way to catch
                        Mordred's agents in their webs of deceit.</i>
                </Tip>
            </HelpSection>

            <HelpSection title="Team Voting Phase">
                <p>
                    Once the Leader has chosen the team, each player including the Leader selects whether or not they
                    think this team should go on the Quest. Once all votes have been collected, they are revealed along
                    with who cast them. The team is approved if the majority of players vote <b>Yes</b> and failed
                    otherwise, including if the vote is tied.
                </p>

                <p>
                    <b>If the team vote fails five times consecutively then the evil wins!</b> The current number of
                    consecutive vote failures is displayed in the <b>Quests</b> tab.
                </p>

                <Tip tipText="strategy">
                    <i>The Leader is proposing the team - but all players have a vote in whether they accept or reject
                        the proposal. The Leader can be evil, or one of the players could be a mistake. Don't feel that
                        you need to accept all the proposed teams. If you reject the team then a new Leader can propose
                        a different team, maybe one without evil on it.</i>
                </Tip>
            </HelpSection>

            <HelpSection title="Quest Phase">
                <p>
                    Each player who was chosen for the Quest selects whether they wish the Quest to fail or succeed.
                    These votes are secret and will not be displayed in association with the player's name. A Quest is
                    completed successfully only if all players on the team vote for it to <b>Pass</b>.
                </p>

                <Tip tipText="note">
                    The 4th Quest (and only the 4th Quest) in games of 7 or more players requires at least two Quest
                    fail votes. The number of votes required to fail each round is displayed in the <b>Quests</b> tab.
                </Tip>
            </HelpSection>
        </HelpSection>

        <HelpSection title="Strategy">
            <p>
                If you aren't confident of everyone on the proposed team, then you should strongly consider rejecting
                the proposal. Rejecting a team is not a sign that you're evil. A group of skilled players will often
                pass on teams several times before agreeing to a team.
            </p>

            <p>
                Watch who approves teams, especially when you disagree. Ask them why they approved - sometimes evil
                players will approve because they know another evil player was included. Merlin can also use his voting
                as a signal, but be careful - the evil players are watching.
            </p>
        </HelpSection>

        <HelpSection title="Game End">
            <p>
                The game ends immediately after three successful or three failed Quests. The evil players win if three
                Quests fail. The game also ends immediately and the evil players win if five teams are rejected in a
                single round.
            </p>

            <p>
                If three Quests are completed successfully, the evil players have a final opportunity to win the game
                if <b>The Assassin</b> role is present in the game. Without revealing their roles, evil players discuss
                and the player with <b>The Assassin</b> role chooses who they think Merlin is. Evil wins if they choose
                correctly, otherwise good players win.
            </p>
        </HelpSection>
    </HelpSection>;