import {Icon} from "../general/Icon";
import {useNavigate} from "react-router-dom";
import React, {useCallback} from "react";
import {createHelpHash} from "../help/createHelpHash";
import styled from 'styled-components/macro';
import {clickable} from "../../styled/mixins";

const themes = {
    light: {
        primary: "#fff",
        hover: "#292929",
        invert: "#4a4a4a",
    },
    dark: {
        primary: "#4a4a4a",
        hover: "rgba(0,0,0,0.05)",
        invert: "#fff"
    }
}

const Bubble = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 5px;
  padding-left: 6px;
`;

const HelpContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 3.25rem;
    height: 3.25rem;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 1023px) {
        right: 3.25rem;
    }
    
    color: ${props => themes[props.$theme].primary};
    ${Bubble} {
        border: 1px solid ${props => themes[props.$theme].primary};
        background-color: ${props => themes[props.$theme].invert};
    }
    &:hover {
        background-color: ${props => themes[props.$theme].hover};
        ${Bubble} {
            background-color: ${props => themes[props.$theme].primary};
            color: ${props => themes[props.$theme].invert};
        }
    }
    ${clickable}
`;
HelpContainer.defaultProps = {
    $theme: 'light'
}

export const HelpButton = ({helpSection, onClick, ...rest}) => {
    const navigate = useNavigate();
    const visitHelp = useCallback(() => {
            onClick && onClick();
            navigate({
                pathname: '/help',
                ...(helpSection && {hash: '#' + createHelpHash(helpSection)})
            })
        }, [navigate, helpSection, onClick]
    );

    return <HelpContainer onClick={visitHelp} {...rest}>
        <Bubble><Icon icon={['fas', 'question']}/></Bubble>
    </HelpContainer>
};