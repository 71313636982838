import {createSelector} from "reselect";

export const getHost = state => state.lobby.host;
export const getMe = state => state.lobby.me;
export const getStarted = state => state.lobby.started;
export const getPhase = state => state.lobby.phase;
export const getConfig = state => state.lobby.config || {};
export const getMessages = state => state.lobby.messages;

export const getIsHost = createSelector(
    [getHost, getMe],
    (host, me) => host === me
);

export const getFilteredConfig = createSelector(
    [getConfig],
    (config = {}) =>
        Object.entries(config).reduce((carry, [key, value]) => {
            if (value !== null && value !== undefined) carry[key] = value;
            return carry;
        }, {})
);