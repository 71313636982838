import {ButtonLevel, ButtonLevelContainer} from "../general/ButtonLevel";
import {PreFooter} from "../layout/PreFooter";
import React from "react";
import {StandardContent} from "../layout/StandardContent";
import {useSelector} from "react-redux";

export function PreFooterButtons({children}) {
    const reconnecting = useSelector(state => state.handshake.reconnecting);
    if (reconnecting) {
        return false;
    }

    return <PreFooter>
        <ButtonLevelContainer>
            <StandardContent>
                <ButtonLevel>
                    {children}
                </ButtonLevel>
            </StandardContent>
        </ButtonLevelContainer>
    </PreFooter>;
}