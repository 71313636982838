import React, {useCallback, useState} from 'react';
import cn from 'classnames';
import logo from 'images/Logo.png';
import lightTextLogo from 'images/LogoLight.png';
import darkTextLogo from 'images/LogoDark.png';
import {Link} from "react-router-dom";
import {HelpButton} from "./HelpButton";
import './Header.scss';

export function useHeaderState() {
    const [open, setOpen] = useState(false);
    const toggleNav = useCallback(() => setOpen(open => !open), [setOpen]);
    const closeNav = useCallback(() => setOpen(false), [setOpen]);

    return {open, toggleNav, closeNav};
}

export function Header(
    {
        theme = 'is-dark',
        open = false,
        toggleNav,
        closeNav,
        helpActive = false,
        helpSection,
        children
    }
) {
    const textLogo = theme === 'is-dark' ? lightTextLogo : darkTextLogo;
    const active = {'is-active': open}

    return <nav className={`navbar ${theme}`} role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
            <Link to="/" className="navbar-item">
                <img src={logo} alt="logo" style={{marginRight: '10px'}}/>
                <img src={textLogo} alt="text logo"/>
            </Link>

            <span role="button" className={cn('navbar-burger burger', active)}
                  aria-label="menu" aria-expanded={open}
                  onClick={toggleNav}
            >
                <span aria-hidden="true"/>
                <span aria-hidden="true"/>
                <span aria-hidden="true"/>
            </span>
        </div>

        <div className={cn('navbar-menu', active)}>
            {children}
        </div>

        <HelpButton
            className={helpActive && "active-page"}
            onClick={closeNav}
            helpSection={helpSection}
        />
    </nav>;
}