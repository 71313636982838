export const PHASE_IN_LOBBY = 'in_lobby';
export const PHASE_IN_GAME  = 'in_game'; // These phase is computed
export const PHASE_ALL      = 'all'; // This phase is computed

export const ACTION_START    = 'game-start';
export const ACTION_GAME_END = 'game-end';
export const ACTION_CONTINUE = 'continue';

export const PLAYER_STATUS = {
    QUEUED: 'queued',
    SPECTATING: 'spectating',
    IN_GAME: 'in_game',
    KICKED: 'kicked',
    REQUESTING_REJOIN: 'requesting_rejoin',
};