import gql from "graphql-tag.macro";

export const MY_LOBBIES = gql`
    query {
        me {
            id
            lobbies {
                code
                game
                players {
                    name
                }
            }
        }
    }
`;

export const ME = gql`
    query {me {id, token}}
`;

export const CREATE_LOBBY = gql`
    mutation CreateLobby($game: String!) {lobbyCreate(game: $game) {code}}
`;

export const CREATE_FEEDBACK = gql`
    mutation ($feedback: FeedbackInput) {
        createFeedback(feedback: $feedback) {id}
    }
`;

export const UPDATE_USER = gql`
    mutation UpdateUser($name: String!) {
        userChangeName(name: $name) {
            token
        }
    }
`;