import React, {useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Select} from "../forms/fields";
import {changeGame} from "actions/play";
import {games} from '../games';

export const GameSelect = React.memo(() => {
    const gameOptions = useMemo(() => (
        Object.entries(games).sort().map(([name, {name: label}]) => ({
            label,
            value: name,
        }))
    ), []);
    const currentGame = useSelector(state => state.handshake.game);
    const dispatch = useDispatch();
    const currentGameOption = gameOptions.find(option => option.value === currentGame);

    const onChange = useCallback((game) => {
        if (game.value !== currentGame) {
            dispatch(changeGame(game.value));
        }
    }, [dispatch, currentGame]);

    return (
        <div>
            <label className="label">Game</label>
            <Select options={gameOptions} onChange={onChange} value={currentGameOption}/>
        </div>
    );
});