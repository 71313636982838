import styled, {css} from 'styled-components/macro';

export const FlexList = styled.ul`
    background-color: rgba(255,255,255,.4);
    min-height: 2.5rem;
    border-radius: 4px;
    
    ${props => props.dragable && css`
        user-select: none;
    `}
`;

export const FlexItem = styled.li`
    display: flex;
    align-items: center;
    border: 1px solid #dbdbdb;
    margin: -1px 0;
    padding: 0 0.5rem;
    min-height: 40px;
    
    &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    
    ${props => props.dragging && css`
        background-color: rgba(255,255,255,.8);
        border-radius: 4px;
        box-shadow: 3px 3px 3px rgba(10,10,10,.05);
    `}
`;