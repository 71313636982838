import React, {useEffect, useState} from 'react';
import {Form, Formik} from "formik";
import {FieldContainer, SubmitButton} from "components/forms/fields";
import {Layout} from "components/layout/Layout";
import {useNavigate} from "react-router-dom";
import {useNextLocation} from "../../libs/useNextLocation";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "selectors/userSelectors";
import {playerValidationSchema} from "shared/usernameValidation";
import {newUserToken} from "../../actions/userActions";
import {useMutation} from "@apollo/client";
import {UPDATE_USER} from "../../graphQL/general";
import {CenterText, SpaceVertical} from "../../styled/Containers";
import {FormikInput} from "../forms/fields";
import {randomItem} from "shared/arrayRandom";

const placeholderNames = [
    'Morty', 'Rick', 'Squanchy', 'Summer', 'Mr. Meeseeks', 'Beth', 'Jerry',
    'Gandalf', 'Legolas', 'Frodo', 'Aragorn', 'Bilbo', 'Gollum', 'Saruman', 'Sauron', 'Gimli', 'Arwen',
    'Vader', 'Han Solo', 'Luke', 'Skywalker', 'Yoda', 'Leia', 'C-3PO', 'R2-D2', 'Obi-Wan', 'Kylo', 'Rey', 'Chewbacca',
    'Kirk', 'Spock', 'Worf', 'Picard', 'Scotty', 'Sulu', 'Uhura',
    'Spider-Man', 'Batman', 'Superman', 'Loki', 'Stark', 'Groot', 'Star-Lord', 'Bane', 'Joker', 'Hulk', 'Riddler',
    'Harry', 'Draco', 'Hermione', 'Voldemort', 'Dumbledore', 'Snape', 'Dobby', 'Hagrid', 'Sirius', 'Luna', 'Neville',
    'Neo', 'Morpheus', 'Trinity', 'Oracle', 'Cypher', 'Dozer', 'Tank', 'Apoc', 'Mouse', 'Switch',
    'Master Chief', 'Arbiter', 'Cortana', 'Grunt', 'Jackal', 'Spark', 'Prophet', 'Elite',
    'Ace', 'Inigo', 'Hal', 'Gromit', 'Red', 'Donnie', 'Captain', 'Bueller', 'Jesus', 'Rocky', 'Snake', 'Ash',
    'Hannibal', 'Doc', 'Sparrow', 'The Dude', 'James Bond', 'Sherlock', 'Dracula',
];

export const PlayerPage = () => {
    const [name, setName] = useState(randomItem(placeholderNames));
    const navigate = useNavigate();
    const nextLocation = useNextLocation('/');
    const {name: userName} = useSelector(getUser);
    const dispatch = useDispatch();
    const [updateUser] = useMutation(UPDATE_USER);

    useEffect(() => {
        const timer = setInterval(
            () => setName(randomItem(placeholderNames)),
            5000
        );
        return () => clearInterval(timer);
    }, []);

    const onSubmit = async (values) => {
        const {data: {userChangeName: {token}}} = await updateUser({
            variables: values
        });
        dispatch(newUserToken(token));
        navigate(nextLocation);
    };

    const playerExists = !!userName;

    return <Layout title={playerExists ? 'Change Your Name' : 'Greetings!'}>
        {!playerExists && <>
            <CenterText $bold>
                Devious Games is a platform for playing some of your favorite board games - minus the board!
            </CenterText>
            <CenterText $bold>What should we call you?</CenterText>
        </>}
        <Formik
            initialValues={{name: userName || ''}}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={playerValidationSchema}
        >
            {form => (
                <Form>
                    <SpaceVertical>
                        <FieldContainer name="name" label={playerExists ? 'Name' : 'My name is...'}>
                            <FormikInput placeholder={name}/>
                        </FieldContainer>
                        <SubmitButton disabled={form.isSubmitting || Object.keys(form.errors).length}>
                            {playerExists ? 'Save' : 'Submit'}
                        </SubmitButton>
                    </SpaceVertical>
                </Form>
            )}
        </Formik>
    </Layout>
};