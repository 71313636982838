import React from "react";
import {SpyfallButton} from "./Spyfall/SpyfallButton";
import {AvalonButton} from "./Avalon/AvalonButton";
import {CluelessArtistButton} from "./CluelessArtist/CluelessArtistButton";
import {HeistButton} from "./Heist/HeistButton";
import {GAMES} from "shared/constants/games";

export const games = {
    [GAMES.SPYFALL]: {
        name: 'Spyfall',
        Component: React.lazy(() => import('./Spyfall/Spyfall')),
        ButtonComponent: SpyfallButton,
    },
    [GAMES.AVALON]: {
        name: 'Avalon',
        Component: React.lazy(() => import('./Avalon/Avalon')),
        ButtonComponent: AvalonButton,
    },
    [GAMES.CLUELESS_ARTIST]: {
        name: 'Clueless Artist',
        Component: React.lazy(() => import('./CluelessArtist/CluelessArtist')),
        ButtonComponent: CluelessArtistButton,
    },
    [GAMES.HEIST]: {
        name: 'Heist',
        Component: React.lazy(() => import('./Heist/Heist')),
        ButtonComponent: HeistButton
    }
};

if (process.env.NODE_ENV === 'development') {
    // games.DeadLast = {
    //     name: 'Dead Last',
    //     eventPrefix: 'deadlast',
    //     Component: React.lazy(() => import('./DeadLast/DeadLast')),
    //     ButtonComponent: DeadLastButton,
    // };
}