import React from "react";
import RotateLoader from "react-spinners/RotateLoader";
import styled from 'styled-components/macro';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 9.5rem);
`

export const CenteredLoading = () =>
    <Container>
        <RotateLoader
            color="#8875B0"
            size={20}
        />
    </Container>;