import React from 'react';
import cn from 'classnames';
import styled from 'styled-components/macro';

const Label = styled.label`
    display: inline-flex;
    align-items: center;
`;

const Input = styled.input`
    flex-shrink: 0;
    margin-right: ${({$hasLabel}) => $hasLabel && '0.5rem'};
`;

export const Selectable = (
    {
        label,
        className,
        labelProps: {className: labelClassName, ...labelProps} = {},
        type = "checkbox",
        ...props
    }
) => (
    <Label className={cn(type, labelClassName)} {...labelProps}>
        <Input
            type={cn(type, className)}
            $hasLabel={!!label}
            {...props}
        />
        {label}
    </Label>
);