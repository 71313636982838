import React from 'react';
import {HelpSection} from "../HelpSection";
import {HelpLink} from "../HelpLink";
import {Tip} from "../../general/Tip";
import {Text} from "../../../styled/Typography";
import {GAMES} from "shared/constants/games";

export const SpyfallHelp = () => <HelpSection title="Spyfall" link={GAMES.SPYFALL} tableOfContents>
    <HelpSection title="Overview">
        <p>
            A game of Spyfall is made up of several short rounds. In each round the players find themselves in a certain
            location with a specific role assigned to each player. One player is always a Spy who doesn’t know where
            they are. The spy’s mission is to listen carefully, identify the location, and keep from blowing their
            cover. Each non-spy must give an oblique hint to the other non-spies suggesting that he knows the location’s
            identity, thus proving he’s not the spy. Observation, concentration, nonchalance, and cunning — you’ll need
            all of them in this game. Stay on your toes!
        </p>
    </HelpSection>

    <HelpSection title="Setup">
        <p>
            Only the Lobby Leader can control the following settings. Changing these settings can dramatically change
            gameplay!
        </p>
        <HelpSection title="Locations Selection">
            <p>
                The locations selection is where you can choose which sets of locations may appear in your game. <b>Spyfall
                1 and 2</b> are presets consisting of just the locations which appear in each corresponding board game.
                These are the sets you may be familiar with if you've played other online Spyfall games. If you choose
                one of these and leave all other options unchecked, your experience will be exactly the same as if you
                played that base game.
            </p>

            <Text $bold>
                Using either the custom locations option, or multiple location sets, is where things get more
                interesting.
            </Text>

            <p>
                With all three location sets checked (<i>as it is by default</i>), the game will randomly use any of the
                48 locations from either of the two base games, as well as an extra set of around 50 more custom-created
                locations. A game containing 90+ locations would be impossible to play, so by default when using any
                location set other than exclusively Spyfall 1 or 2 locations, the total number of locations in any game
                will be limited to 18. This can be changed in the <b>Advanced Options</b>.
            </p>

            <p>
                We strongly recommend using more than just the Spyfall 1 or 2 locations alone. Letting the game pick
                locations randomly provides more dynamic gameplay from round to round since all players will have to
                continually tailor their questions and answers to the list of location used in that particular round.
            </p>
        </HelpSection>

        <HelpSection title="Advanced Options">
            <p>
                The options listed below are for experienced players. If this is your first time playing Spyfall feel
                free to skip this section and return to it after playing using the default settings for a while.
            </p>

            <HelpSection title="Game Duration">
                <p>
                    Game duration is the amount of time each game will last. If this is left unset then the game will be
                    2 minutes more than the number of players in the game.
                </p>

                <p>
                    Generally speaking, the duration of each game does not directly affect difficulty. Instead it should
                    be set based on the experience and talkativeness of the players. Less experienced players may need
                    more time, where more experienced players may need less time.
                </p>
            </HelpSection>

            <HelpSection title="Limit the Number of Locations">
                <div>
                    The <b>Limit Number of Locations</b> option controls how many total locations will appear in each
                    game. This option directly affects difficulty for the spy:
                    <ul>
                        <li>
                            A <b>higher number of locations</b> makes it <b>harder for the spy</b> to narrow down the
                            list and guess the correct location. Answers will generally apply to more locations when the
                            number of locations is higher.
                        </li>
                        <li>
                            A <b>lower number of locations</b> makes it <b>easier for the spy</b> to narrow down the
                            list and guess the correct location. Fewer locations makes it harder to think of questions
                            and answers which don't give the location away.
                        </li>
                    </ul>
                </div>

                <p>
                    The default values for each <HelpLink>Location Selection</HelpLink> option is tuned for reasonable
                    difficulty. Use this as a starting point for determining the correct number of locations for your
                    group.
                </p>
            </HelpSection>

            <HelpSection title="Number of Spies">
                <p>
                    Here you can change the number of spies that will appear in each game. There aren't really set rules
                    for how to play when there are multiple spies, so you'll have to invent your own house-rules if you
                    plan on playing with multiple spies.
                </p>
            </HelpSection>

            <HelpSection title="Post Game Discussion Timer">
                <p>
                    This option can be enabled to add an additional timer which appears after questioning has concluded.
                    You can use this timer however you like, for example you could have the spy automatically win if
                    they are not accused before the timer expires, or you can simply limit discussion at that point.
                </p>
            </HelpSection>
        </HelpSection>
    </HelpSection>

    <HelpSection title="Game Flow">
        <p>
            Starting with the player marked "<span className="has-text-danger">1st</span>" in the player list, a player
            will ask one of the other players a question, calling them by name: “Tell me, John...” The questions usually
            pertain to the current location. This is prudent, but not mandatory. Questions are asked once; no follow-up
            questions are allowed. Responses can take any form. The player who answers the question proceeds to ask any
            other player a question of their own, but cannot ask a question of the player who just asked them a
            question. The order in which the questions are asked is established by the players themselves and based on
            the suspicions they have after hearing the questions and answers.
        </p>

        <Tip tipText="note">
            If you are not the spy, your card will also specify your role at the given location. Before the beginning of
            the game, the players should agree on whether they will adhere to the role on their card and play the
            corresponding role or not. We strongly recommend doing this in order to make the game more interesting, but
            not for your first few game sessions. For example, if you’re a college professor and somebody asks you why
            you missed the faculty party, you can complain about your age and arthritis; if you’re a student, you might
            want to express your outrage at not having been invited by the senior faculty.
        </Tip>

        <Text $bold>
            Asking and answering questions continues until the game time has elapsed. At this point no questions can be
            asked or answers can be given.
        </Text>

        <HelpSection title="Voting for the Spy">
            <p>
                At any point in the game, any player (spy included) can accuse another player of being the spy and call
                a vote for that player. If the other players take this accusation seriously, they can officially vote by
                raising their hand or declaring by name: e.g. "I vote for John." If all non-accused players vote for the
                accused spy, the game immediately ends, even if there is time left on the clock. At this point proceed
                to the instructions in the next section <HelpLink>Finishing the Game</HelpLink>.
            </p>
        </HelpSection>

        <HelpSection title="Guessing the Location">
            <p>
                At any point in the game, the spy can attempt to guess the location, causing the game to end. The spy
                wins or loses based on if their guess is correct.
            </p>
        </HelpSection>
    </HelpSection>

    <HelpSection title="Finishing the Game">
        <p>
            When the timer expires, or a player has been voted as the spy, the game is over.
        </p>

        <div>
            <Text>
                <b>If no player has been accused:</b> Players must decide unanimously who the spy is, or the spy
                wins.
            </Text>
            <Text $bold>When a spy has been voted for:</Text>
            <ul>
                <li><b>If that player is not the spy</b>, the spy wins.
                </li>
                <li><b>If that player is the spy</b>, then they must reveal themselves as the spy. At this point the
                    spy has one chance to guess the location. They win if they guess the location correctly, and
                    lose otherwise.
                </li>
            </ul>
        </div>

        <HelpSection title="Scoring (Optional)">
            <p>
                Scoring is optional and is not currently handled by this app. If you'd like to keep track of scoring
                between multiple games, you can do it separately using this system:
            </p>

            <HelpSection title="Spy Victories">
                <ul>
                    <li>The spy earns 2 points.</li>
                    <li>The spy earns 2 bonus points if they correctly guess the location before being accused.</li>
                </ul>
            </HelpSection>

            <HelpSection title="Civilian Victories">
                <ul>
                    <li>Each non-spy player earns 1 point.</li>
                    <li>The player who initiated the successful accusation of the spy earns 1 extra point.</li>
                </ul>
            </HelpSection>

            <Tip tipText="note from the developer">
                I included scoring in this help guide since it's in the Original Spyfall game, but I don't personally
                use scoring when playing. I just don't think it adds much to the game, and can actually detract from the
                experience depending on who is playing. Also, the scoring system described here is a modified version of
                the original scoring system, which rewards passive spies far too heavily. Feel free to look up the
                original rulebook if you're interesting in learning more.
            </Tip>
        </HelpSection>
    </HelpSection>

    <HelpSection title="Strategy">
        <HelpSection title="As the Spy">
            <p>
                As the spy, you must figure out the location without giving yourself away. There's no doubt that being
                the spy is stressful. You will be asked questions you cannot possibly answer, and will have to spin lies
                and stay on your toes.
            </p>

            <div>
                <Text $bold>Tips for asking / answering questions:</Text>
                <ul>
                    <li>
                        You can justify vague answers by saying that you're trying to keep the location secret from the
                        spy.
                    </li>
                    <li>
                        You can use roles to deflect. If the location is the School, then saying you would be there on
                        weekends seems very suspicious, but what if your role was the Weekend Janitor?
                    </li>
                    <li>
                        If you're quick on your feet, you may be able to deflect from an exceptionally poor answer by
                        implying that you're trying to bait agreement from a spy.
                    </li>
                </ul>
            </div>

            <Tip>
                When you are not involved in the question being asked, it's a good idea to whittle away at the list of
                possible locations. You can click any location to cross it out - indicating that location has been ruled
                out.
            </Tip>
        </HelpSection>

        <HelpSection title="As a Civilian">
            <p>
                As a Civilian, you know the location and your role, and must sus out who is the spy. Look for signs of
                suspicion
            </p>

            <div>
                <Text $bold>Tips for asking / answering questions:</Text>
                <ul>
                    <li>
                        Always make sure your questions and answers are ambiguous enough to not give the location away.
                        An example of a terrible question would be to ask "Are there many pirates here?" while on the
                        Pirate Ship.
                    </li>
                    <li>
                        Your questions and answers can contain coded messages to help prove your innocence, as well as
                        help identify your fellow civilians. Inside jokes or references to a prior events which relate
                        to the location can immediately give you the upper hand on the spy.
                    </li>
                </ul>
            </div>

            <Tip>
                When you hear a question or answer which seems to prove another player's innocence, you can tap their
                name in the player list to cross it out. This can be a helpful way of deducing who the spy is!
            </Tip>
        </HelpSection>
    </HelpSection>
</HelpSection>;