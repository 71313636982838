import styled from "styled-components/macro";
import {fontProps} from "./mixins";

export const Container = styled.div`
    ${fontProps};
`

export const Vertical = styled(Container)`
    display: flex;
    flex-direction: column;
`

export const SpaceVertical = styled(Vertical)`
    margin: -0.25rem 0;
    > * {padding: 0.25rem 0;}
    @media (min-width: 350px) {
        margin: -0.5rem 0;
        > * {padding: 0.5rem 0;}
    }
    @media (min-width: 700px) {
        margin: -0.75rem 0;
        > * {padding: 0.75rem 0;}
    }
    @media (min-width: 1024px) {
        margin: -1rem 0;
        > * {padding: 1rem 0;}
    }
`

export const CenterAll = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const WrapContainer = styled(Container)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    margin: -0.5rem;
    > * {
        margin: 0.5rem;
    }
`

export const CenterText = styled(Container)`
    text-align: center;
`