import gql from "graphql-tag.macro";

export const USER_FEED = gql`
    query ($pagination: Pagination) {
        userFeed(pagination: $pagination) {
            cursor
            items {
                id
                name
                created
                lobbiesCreated
                currentIp
            }
        }
    }
`;

export const USER_BY_ID = gql`
    query ($id: ID!) {
        userById(id: $id) {
            name
            lobbiesCreated
            currentIp
            ips
            lobbies(includeKicked: true) {
                code
                game
                players {
                    name
                    socketId
                    userId
                }
            }
        }
    }
`

export const LOBBY_FEED = gql`
    query ($pagination: Pagination) {
        lobbyFeed(pagination: $pagination) {
            cursor
            items {
                code
                game
                players(includeKicked: true) {
                    socketId
                    status
                    name
                    userId
                }
            }
        }
    }
`;

export const LOBBY_BY_CODE = gql`
    query ($code: String) {
        lobbyByCode(code: $code) {
            phase
            host
            game
            players(includeKicked: true) {
                id
                name
                status
                socketId
                user {
                    id
                    currentIp
                }
            }
        }
    }
`;

export const FEEDBACK_FEED = gql`
    query ($pagination: Pagination) {
        feedbackFeed(pagination: $pagination) {
            cursor,
            items {
                name
                email
                feedback
                location
            }
        }
    }
`;

export const USER_LOGIN_AS_ADMIN = gql`
    mutation DoStuff ($username: String, $password: String) {
        userLoginAsAdmin(username: $username, password: $password) {
            success
            remainingAttempts
            user {
                token
            }
        }
    }
`;

export const USER_LOGOUT_AS_ADMIN = gql`
    mutation {userLogoutAsAdmin {token}}
`;

export const USER_BAN = gql`
    mutation ($id: ID!) {
        userBan(id: $id)
    }
`;

export const USER_MESSAGE = gql`
    mutation ($id: ID!, $message: String!, $type: String) {
        userMessage(id: $id, message: $message, type: $type)
    }
`;

export const LOBBY_MESSAGE = gql`
    mutation ($code: String!, $message: String!, $type: String) {
        lobbyMessage(code: $code, message: $message, type: $type)
    }
`;