import styled from "styled-components/macro";

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

export const ContentContainer = styled.div`
    flex: 1 0 auto;
    margin-bottom: 2rem;
`;
