import React from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {getIsAdmin} from "selectors/userSelectors";
import {getUser} from "../selectors/userSelectors";

export const ProtectedRoute = ({element, children, allow, redirect}) => {
    const location = useLocation();
    return allow
        ? element || children
        : <Navigate to={redirect} replace={true} state={{from: location.pathname}}/>;
};

export const NameRequiredRoute = (props) => {
    const user = useSelector(getUser);
    return <ProtectedRoute allow={!!user.id} redirect="/hello" {...props}/>;
};

export const AdminRoute = (props) => {
    const isAdmin = useSelector(getIsAdmin);
    return <ProtectedRoute allow={isAdmin} redirect="/admin/login" {...props}/>;
};
