import React from 'react';
import {Icon} from "../../general/Icon";
import styled from 'styled-components/macro';

const Container = styled.div`
    height: 40px;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2rem;
    cursor: ${props => props.cursor};
`;

export const IconButton = ({icon, ...rest}) =>
    <Container {...rest}><Icon icon={icon}/></Container>;