import {css} from 'styled-components/macro';

export const fontProps = ({theme, $bold, $fontSize, $color}) => css`
    font-weight: ${$bold && 'bold'};
    font-size: ${$fontSize && theme.fontSize[$fontSize]};
    color: ${$color && theme.colors[$color]};
`

export const clickable = css`
    cursor: pointer;
    user-select: none;
`;

export const initialList = css`
    ul, ol {
        margin-left: 1.5rem;
    }
    li {
        list-style-type: initial;
    }
`;
