import rootReducer from "./reducers";
import thunk from "redux-thunk";
import {changeUserToken, clearUserToken} from "./actions/userActions";
import {apolloClient} from "./apolloClient";
import {ME} from "./graphQL/general";
import {applyMiddleware, createStore as baseCreateStore} from "redux";

export function createStore() {
    const store = baseCreateStore(rootReducer, applyMiddleware(thunk));

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
    }

    window.onstorage = (event) => {
        if (event.storageArea === localStorage) {
            if (event.key === 'token') {
                store.dispatch(changeUserToken(event.newValue));
            }
        }
    };

    if (localStorage.getItem('token')) {
        apolloClient.query({query: ME})
            .then(({data}) => {
                if (data && !data.me) {
                    store.dispatch(clearUserToken());
                }
            })
            .catch(console.error);
    }

    return store;
}