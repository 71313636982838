const initialState = {
    client: undefined,
    offset: undefined,
};

export const timesyncReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'NEW_TIMESYNC':
            return {
                ...state,
                client: action.client,
            };
        case 'TIMESYNC_OFFSET':
            return {
                ...state,
                offset: action.offset
            };
        default:
            return state;
    }
};
