import {createReducer} from "libs/createReducer";


export const cluelessArtistReducer = createReducer({
    initialState: {
        changing: false,
    },
    immerReducers: {
        CLUELESS_ARTIST_COLOR_CHANGE(state) {
            state.changing = true;
        },
        CLUELESS_ARTIST_COLOR_CHANGE_DONE(state) {
            state.changing = false;
        }
    }
})