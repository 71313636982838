import {produce} from 'immer';

export const createReducer = ({initialState = {}, immerReducers = {}, reducers = {}}) =>
    (state = initialState, action) => {
        const type = action.type;
        if (immerReducers.hasOwnProperty(type)) {
            return produce(immerReducers[type])(state, action);
        } else if (reducers.hasOwnProperty(type)) {
            return reducers[type](state, action);
        }
        return state;
    }