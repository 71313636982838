import React from 'react';
import cn from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {kickPlayer, promotePlayer} from "actions/lobby";
import {getIsHost} from "selectors/lobbySelectors";
import {CrownIcon, DisconectedIcon} from "../LobbyIcons";
import {IconButton} from "./IconButton";
import styled from 'styled-components/macro'
import {Link, useLocation} from "react-router-dom";
import {PLAYER_STATUS} from "shared/constants/general";

const NameContainer = styled.div`
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0.5rem;
`

const StatusContainer = styled.div`
    flex-grow: 1;
    padding: 0.25rem;
`

export function PlayerRow({player}) {
    const [host, isHost, waiting, me] = useSelector(state => [
        state.lobby.host,
        getIsHost(state),
        state.lobby.waiting,
        state.lobby.me,
    ]);
    const dispatch = useDispatch();
    const location = useLocation();

    return <>
        <NameContainer>
            {player.id === me
                ? <Link
                    to="/hello"
                    state={{from: location.pathname}}
                    className="has-text-success"
                >
                    {player.name}
                </Link>
                : player.name
            }
        </NameContainer>
        <StatusContainer>
            {player.id === host && <>{' '}<CrownIcon/></>}
            {!player.connected && <>{' '}<DisconectedIcon/></>}
        </StatusContainer>
        {isHost && player.id !== me && <>
            <IconButton
                onClick={() => dispatch(promotePlayer(player))}
                icon="angle-double-up"
                className={cn({
                    'has-text-warning': !waiting,
                    'has-text-grey-light': waiting
                })}
                cursor="pointer"
            />
            {player.status !== PLAYER_STATUS.IN_GAME && <IconButton
                onClick={() => dispatch(kickPlayer(player))}
                icon="times"
                className={cn({
                    'has-text-danger': !waiting,
                    'has-text-grey-light': waiting
                })}
                cursor="pointer"
            />}
        </>}
    </>;
}