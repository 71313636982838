import {useContext} from "react";
import {ContainerContext} from "./FieldContainer";
import {useField as useBaseField} from "formik";

export function useField(nameOrProps) {
    const {name} = useContext(ContainerContext);
    const resolvedName = name || nameOrProps;
    if (!resolvedName && !resolvedName.name) {
        throw new Error('Name not found in useField');
    }
    const [field, meta, helper] = useBaseField(resolvedName);

    return {
        field,
        meta,
        helper,
        status: {
            isTouched: meta.touched,
            isValid: meta.touched && !meta.error,
            isErrored: meta.touched && meta.error,
        }
    };
}