export const newUserToken = (token) => (dispatch) => {
    localStorage.setItem('token', token);
    dispatch(changeUserToken(token));
};

export const changeUserToken = (token) => ({
    type: 'USER_TOKEN_CHANGED',
    token
});

export const clearUserToken = () => (dispatch) => {
    localStorage.removeItem('token');
    dispatch({type: 'USER_TOKEN_CLEARED'});
};