import React from "react";
import {useSelector} from "react-redux";
import {getStarted} from "selectors/lobbySelectors";
import {getIsSpectating} from "selectors/playersSelectors";

export function PlayerStatus() {
    const [started, isSpectating] = useSelector(state => [
        getStarted(state),
        getIsSpectating(state),
    ]);

    return (
        <div className="has-text-centered has-text-weight-bold">
            {started
                ? <>
                    Game in progress<br/>
                    {isSpectating
                        ? 'You are spectating'
                        : 'You will join the next round.'
                    }
                </>
                : <>
                    {isSpectating
                        ? <>You are currently spectating<br/> You will not participate in the next game!</>
                        : 'Waiting for game to start...'
                    }
                </>
            }
        </div>
    );
}