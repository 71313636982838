import React, {useMemo} from 'react';
import {HelpLink} from "./HelpLink";
import {HelpSection} from "./HelpSection";
import {HelpTitle} from "./HelpTitle";

function buildTopicsFromChildren(children, level = 0) {
    const topics = [];
    React.Children.forEach(children, element => {
        if (!React.isValidElement(element)) {
            return;
        }
        if (element.type === React.Fragment || element.type !== HelpSection) {
            topics.push.apply(topics, buildTopicsFromChildren(element.props.children, level));
            return;
        }
        let topic = {
            title: element.props.title,
        };
        if (element.props.children) {
            let childTopics = buildTopicsFromChildren(element.props.children, level + 1);
            if (childTopics.length) {
                topic.children = childTopics;
            }
        }
        topics.push(topic);
    });
    return topics;
}

const TopicsToTableOfContents = ({topics}) =>
    <ul>
        {topics.map(({title, children = []}) => (
            <li key={title}>
                <HelpLink>{title}</HelpLink>
                {children.length > 0 && <TopicsToTableOfContents topics={children}/>}
            </li>
        ))}
    </ul>;

export function TableOfContents({children}) {
    const topics = useMemo(
        () => buildTopicsFromChildren(children),
        [children]
    );
    return <div>
        <HelpTitle size={25}>Table of Contents</HelpTitle>
        <TopicsToTableOfContents topics={topics}/>
    </div>;
}