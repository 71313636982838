import React, {useCallback, useEffect, useState} from "react";
import throttle from 'lodash.throttle';
import {Icon} from "./Icon";
import styles from './ScrollToTop.module.scss';
import cn from 'classnames'
import {useLocation, useNavigate} from "react-router-dom";

export const ScrollToTop = ({showPositionY = 500}) => {
    const [showScrollToTop, setScrollToTop] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const monitorScrollPosition = throttle(() => {
            setScrollToTop(window.scrollY >= showPositionY);
        }, 500);

        window.addEventListener("scroll", monitorScrollPosition, {passive: true});
        return () => window.removeEventListener("scroll", monitorScrollPosition);
    }, [showPositionY]);

    const scrollUp = useCallback(() => {
        if (location.hash) {
            navigate({...location, hash: undefined});
        }
        window.scrollTo(0, 0);
    }, [location, navigate]);

    return <div
        className={cn(styles.container, {[styles.show]: showScrollToTop})}
        onClick={scrollUp}
    >
        <Icon icon="arrow-up"/>
    </div>
};