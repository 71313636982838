import * as Yup from "yup";

const badNameRegexes = [
    /n(i|[^a-zA-Z])gg(.r|a|[^a-zA-Z])/i,
    /(\s|^)f(a|[^a-zA-Z])g/i,
];
const isBadName = (name) =>
    badNameRegexes.some(regex => name.toLowerCase().match(regex));

export const playerValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(12, 'Too long! Try a nickname maybe?')
        .test('notBad', 'Name is not allowed', name => !name || !isBadName(name))
        .required('Required'),
});