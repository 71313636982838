import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {LeaveButton} from "./LeaveButton";
import {toggleSpectating} from "actions/lobby";
import {ConfirmButton} from "../general/ConfirmButton";
import {getIsHost, getStarted} from "selectors/lobbySelectors";
import {getPlayer} from "selectors/playersSelectors";
import {getWaiting} from "../../selectors/handshakeSelectors";
import {PLAYER_STATUS} from "shared/constants/general";
import {useFormikContext} from "formik";
import {PreFooterButtons} from "./PreFooterButtons";

export const LobbyButtonsNonHost = () =>
    <PreFooterButtons><LobbyButtonsGeneral/></PreFooterButtons>;

export function LobbyButtonsGeneral() {
    const [waiting, spectateOnCooldown, player] = useSelector(state => [
        state.lobby.waiting,
        state.lobby.spectateOnCooldown,
        getPlayer(state),
    ]);
    const dispatch = useDispatch();

    return <>
        <LeaveButton/>
        <button
            type="button"
            className="button is-info"
            disabled={waiting || spectateOnCooldown}
            onClick={() => dispatch(toggleSpectating())}
        >
            {player.status === PLAYER_STATUS.SPECTATING ? 'Participate' : 'Spectate'}
        </button>
    </>
}

export function LobbyButtonsHost(
    {
        gameEnd,
        canStart,
    }
) {
    const [isHost, started, waiting] = useSelector(state => [
        getIsHost(state),
        getStarted(state),
        getWaiting(state),
    ]);
    const {submitForm} = useFormikContext();

    return <PreFooterButtons>
        <LobbyButtonsGeneral/>
        {isHost && (
            started
                ? (
                    <ConfirmButton
                        type="button"
                        className="button is-danger"
                        disabled={waiting}
                        onClick={gameEnd}
                    >
                        End Game
                    </ConfirmButton>
                )
                : (
                    <button
                        className="button is-success"
                        disabled={waiting || !canStart}
                        onClick={submitForm}
                    >
                        Start
                    </button>
                )
        )}
    </PreFooterButtons>
}