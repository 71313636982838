import React, {useCallback, useState} from 'react';
import {Layout} from "components/layout/Layout";
import {useNavigate} from "react-router-dom";
import {games} from "components/games";
import {useSelector} from "react-redux";
import {getUser} from "selectors/userSelectors";
import {useNotification} from "../../general/Notification";
import {useMutation, useQuery} from "@apollo/client";
import {GamesContainer} from "./GamesContainer";
import {LobbiesList} from "./LobbiesList";
import {JoinCodeForm} from "./JoinCodeForm";
import {CREATE_LOBBY, MY_LOBBIES} from "../../../graphQL/general";

export const GamesPage = () => {
    const [creatingLobby, setCreatingLobby] = useState(false);
    const navigate = useNavigate();
    const userPlayer = useSelector(getUser);
    const notification = useNotification();
    const results = useQuery(MY_LOBBIES, {
        fetchPolicy: "cache-and-network",
        pollInterval: 60000,
    });
    const [lobbyCreate] = useMutation(CREATE_LOBBY);
    const lobbies = results.data?.me?.lobbies ?? [];

    const createNewLobby = useCallback((game) => async () => {
        try {
            setCreatingLobby(true);

            const {data: {lobbyCreate: {code}}} = await lobbyCreate({
                variables: {
                    ...userPlayer,
                    game
                }
            });

            // noinspection JSIgnoredPromiseFromCall
            navigate(`/play/${code}`);
        } catch (e) {
            notification.open({message: `Failed to create new lobby. ${e.message}`});
        }
    }, [navigate, userPlayer, notification, lobbyCreate]);

    return <Layout title="Home">
        {notification.render}
        <section>
            <p className="has-text-weight-bold">Join an existing lobby</p>
            <JoinCodeForm/>
        </section>

        <section>
            <p className="has-text-weight-bold">Create a new lobby</p>
            <GamesContainer>
                {Object.entries(games).map(([game, {ButtonComponent}]) => (
                    <ButtonComponent key={game} disabled={creatingLobby} onClick={createNewLobby(game)}/>
                ))}
            </GamesContainer>
        </section>

        {lobbies?.length > 0 && (
            <section>
                <div className="has-text-weight-bold">Lobbies you have joined</div>
                <LobbiesList lobbies={lobbies}/>
            </section>
        )}
    </Layout>
};