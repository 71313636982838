import React from 'react';
import {Layout} from "components/layout/Layout";
import {FeedbackForm} from "../feedback/FeedbackForm";
import {Form} from "formik";
import {Notification} from "../general/Notification";

export const FeedbackPage = () => {
    return <Layout mode="standard" title="Feedback">
        <FeedbackForm>
            {({form, submit, sent}) => <>
                {sent && <Notification className="is-success">Feedback Sent. Thanks!</Notification>}
                <Form>
                    {form}
                    <br/>
                    {submit}
                </Form>
            </>}
        </FeedbackForm>
    </Layout>
};