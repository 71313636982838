import React from 'react';
import ReactSelect from 'react-select';
import './Select.scss';
import cn from "classnames";

export const Select = ({isValid, isErrored, label, ...props}) =>
    <ReactSelect
        classNamePrefix="bulma"
        className={cn({'is-success': isValid, 'is-danger': isErrored})}
        {...props}
    />;