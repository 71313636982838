import React from 'react';
import {Form, Formik} from "formik";
import {FieldContainer, FormikInput, SubmitButton} from "components/forms/fields";
import {Layout} from "components/layout/Layout";
import {Navigate} from "react-router-dom";
import {useNotification} from "../../general/Notification";
import {useNextLocation} from "../../../libs/useNextLocation";
import {useDispatch, useSelector} from "react-redux";
import {newUserToken} from "../../../actions/userActions";
import {getIsAdmin} from "selectors/userSelectors";
import * as Yup from 'yup';
import {useMutation} from "@apollo/client";
import {USER_LOGIN_AS_ADMIN} from "../../../graphQL/admin";

const loginSchema = Yup.object().shape({
    username: Yup.string().required('Cannot be blank'),
    password: Yup.string().required('Cannot be blank'),
});

export const AdminLoginPage = () => {
    const notification = useNotification();
    const nextLocation = useNextLocation('/admin/lobbies', ['/admin/logout']);
    const dispatch = useDispatch();
    const isAdmin = useSelector(getIsAdmin);
    const [login] = useMutation(USER_LOGIN_AS_ADMIN);

    const onSubmit = async (values, {setSubmitting}) => {
        try {
            const {data: {userLoginAsAdmin}} = await login({
                variables: values
            });

            const {success, remainingAttempts, user: {token}} = userLoginAsAdmin;

            if (remainingAttempts === 0) {
                return window.location.reload();
            }

            if (success) {
                dispatch(newUserToken(token));
            } else {
                notification.open({
                    message: <p>
                        Credentials invalid.<br/>You have {remainingAttempts} remaining login attempts.
                    </p>
                });
                setSubmitting(false);
            }
        } catch (e) {
            notification.open({message: e.message})
        }
    };

    return <Layout title="Admin Login">
        {isAdmin && <Navigate to={nextLocation}/>}
        {notification.render}
        <Formik
            initialValues={{username: '', password: ''}}
            validationSchema={loginSchema}
            onSubmit={onSubmit}
        >
            {({submitting}) => (
                <Form>
                    <FieldContainer name="username" label="Username">
                        <FormikInput/>
                    </FieldContainer>
                    <FieldContainer name="password" label="Password">
                        <FormikInput type="password"/>
                    </FieldContainer>
                    <br/>
                    <SubmitButton disabled={submitting}/>
                </Form>
            )}
        </Formik>
    </Layout>
};