import {createSelector} from "reselect";
import {getMe, getStarted} from "./lobbySelectors";
import {PLAYER_STATUS} from "shared/constants/general";

export const getPlayersById = state => state.players.byId;
export const getPlayerOrder = state => state.players.order;
export const getUnsortedPlayers = createSelector([getPlayersById], playersById => Object.values(playersById));
export const getPlayers = createSelector(
    [getPlayersById, getPlayerOrder],
    (playersById, playerOrder) => playerOrder.map(id => playersById[id])
);
export const getPlayer = createSelector(
    [getMe, getPlayersById],
    (me, playersById) => playersById[me]
);
export const getMyIndex = createSelector(
    [getMe, getPlayerOrder],
    (me, orderedPlayers) => orderedPlayers.indexOf(me)
);
export const getIsSpectating = createSelector(
    [getPlayer],
    player => player.status === PLAYER_STATUS.SPECTATING
);
export const getInGame = createSelector(
    [getPlayer],
    (player) => player.status === PLAYER_STATUS.IN_GAME
);
export const getIsKicked = createSelector(
    [getPlayer],
    (player) => {
        const status = player && player.status;
        return status === PLAYER_STATUS.KICKED || status === PLAYER_STATUS.REQUESTING_REJOIN
    }
);
export const getHasRequestedRejoin = createSelector(
    [getPlayer],
    (player) => player.status === PLAYER_STATUS.REQUESTING_REJOIN
);
export const getPlayerLists = createSelector(
    [getPlayers, getStarted],
    (players, started) => {
        const activePlayers = [];
        const waitingPlayers = [];
        const spectatingPlayers = [];
        players.forEach(player => {
            if (player.status === PLAYER_STATUS.SPECTATING) {
                spectatingPlayers.push(player);
            } else if (started && player.status === PLAYER_STATUS.QUEUED) {
                waitingPlayers.push(player);
            } else if (player.status === PLAYER_STATUS.QUEUED || player.status === PLAYER_STATUS.IN_GAME) {
                activePlayers.push(player);
            }
        });
        return {activePlayers, waitingPlayers, spectatingPlayers};
    }
);
export const getQueuedPlayers = createSelector(
    [getPlayers],
    players => {
        return players.filter(player => player.status === PLAYER_STATUS.QUEUED)
    }
);
export const getPlayersRequestingRejoin = createSelector(
    [getPlayers],
    players => players.filter(player => player.status === PLAYER_STATUS.REQUESTING_REJOIN)
)
export const getQueuedPlayerCount = createSelector(
    [getQueuedPlayers],
    players => players.length
);
export const getInGamePlayers = createSelector(
    [getPlayers],
    players => players.filter(player => player.status === PLAYER_STATUS.IN_GAME)
);