import React from 'react';
import {Layout} from "../layout/Layout";
import {ButtonLevel} from "../general/ButtonLevel";
import {useDispatch, useSelector} from "react-redux";
import {LeaveButton} from "./LeaveButton";
import {requestRejoin} from "actions/lobby";
import {getHasRequestedRejoin} from "selectors/playersSelectors";
import {CenterText} from "../../styled/Containers";
import {SubHeading} from "../../styled/Typography";
import {getWaiting} from "../../selectors/handshakeSelectors";

export function KickedLobby() {
    const [hasRequestedRejoin, waiting] = useSelector(state => [
        getHasRequestedRejoin(state),
        getWaiting(state),
    ]);
    const dispatch = useDispatch();

    return <Layout title="Kicked">
        <CenterText>
            <SubHeading>You've been kicked from this lobby!</SubHeading>
        </CenterText>
        {hasRequestedRejoin && <CenterText $bold>
            You requested to rejoin. Please wait.
        </CenterText>}
        <ButtonLevel>
            <LeaveButton/>
            {!hasRequestedRejoin && (
                <button
                    className="button is-link"
                    onClick={() => dispatch(requestRejoin())}
                    disabled={waiting}
                >
                    Request to Rejoin
                </button>
            )}
        </ButtonLevel>
    </Layout>;
}