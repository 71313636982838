import styled, {css} from 'styled-components/macro';
import PropTypes from 'prop-types';
import React from "react";

export const GridContainer = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
    
    ${props => props.striped && css`
        ${Row}:nth-child(odd) ${Cell} {
            background: #f5f5f5;
        }
    `}
    
    ${props => props.verticalBorders && css`
        ${Cell} {
            border-right: 2px solid #bbb;
            &:last-of-type {
                border-right: none;
            }
        }
    `}
`
GridContainer.propTypes = {
    striped: PropTypes.bool,
    verticalBorders: PropTypes.bool,
}

export const Row = styled.div`
    display: flex;
    &:last-of-type {
        margin-bottom: -2px;
    }
`;

export const Cell = styled.div`
    flex-grow: ${({grow}) => grow ?? 1};
    flex-shrink: ${({shrink}) => shrink ?? 0};
    flex-basis: ${({width}) => width ?? ""};
    overflow: hidden;
    padding: 10px;
    border-bottom: 2px solid #bbb;
    ${props => props.flex
        ? css`
            display: flex;
            align-items: ${({align}) => align ?? 'center'};
            justify-content: ${({justify}) => justify ?? "center"};
        `
        : css`
            text-align: ${({align}) => align ?? 'center'};
        `
    };
`;
Cell.propTypes = {
    width: PropTypes.string,
    grow: PropTypes.number,
    shrink: PropTypes.number,
    flex: PropTypes.bool,
    align: PropTypes.string,
    justify: PropTypes.string,
};

export const HeadingCell = styled(Cell).attrs(() => ({centered: true}))`
    align-items: flex-end;
    font-weight: bold;
    background: ${props => props.background ?? "#8875b0"} !important;
    border-bottom: none;
    color: #fff;
`
HeadingCell.propTypes = {
    background: PropTypes.string,
};

export const CustomCell = (defaults) => ({
    Heading: (props) => <HeadingCell {...defaults} {...props}/>,
    Cell: (props) => <Cell {...defaults} {...props}/>
})