import {createReducer} from "libs/createReducer";

const initialState = {
    token: localStorage.getItem('token'),
};

export const userReducer = createReducer({
    initialState,
    immerReducers: {
        USER_TOKEN_CHANGED(state, {token}) {
            state.token = token;
        },
        USER_TOKEN_CLEARED(state) {
            state.token = undefined;
        },
    }
});