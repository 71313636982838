import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import styles from './ConfirmButton.module.css';

export function ConfirmButton(
    {
        children,
        onClick,
        className,
        timeout = 2500,
        confirmText = 'Confirm',
        ...rest
    }
) {
    const [moving, setMoving] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [timer, setTimer] = useState(undefined);

    const confirm = (event) => {
        if (moving) {
            event.preventDefault();
            setMoving(false);
        } else {
            if (confirming === true) {
                clearTimeout(timer);
                if (onClick) {
                    event.preventDefault();
                    onClick(event);
                }
            } else {
                event.preventDefault();
                setConfirming(true);
                setTimer(setTimeout(() => setConfirming(false), timeout));
            }
        }
    };

    // Recreate function to clear timeout on unmount if timer is changed
    useEffect(() => () => clearTimeout(timer), [timer]);

    return (
        <button
            className={cn('button', className, {[styles.confirming]: confirming})}
            onClick={confirm}
            onTouchMove={() => setMoving(true)}
            onTouchEnd={confirm}
            {...rest}
        >
            {confirming
                ? confirmText
                : children
            }
        </button>
    );
}