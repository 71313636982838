import React from 'react';
import {PlayerRow} from "./PlayerRow";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useDispatch, useSelector} from "react-redux";
import {changePlayerOrder} from "actions/lobby";
import {getIsHost} from "selectors/lobbySelectors";
import {FlexItem, FlexList} from "./FlexList";
import {IconButton} from "./IconButton";

const reorder = (array, startIndex, endIndex) => {
    const result = [...array];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export function PlayerList({players, listId = 'player-list', reorderable = true,}) {
    const [waiting, isHost] = useSelector(state => [state.lobby.waiting, getIsHost(state)]);
    const dispatch = useDispatch();

    function onDragEnd({source, destination}) {
        if (!destination || destination.index === source.index) {
            return;
        }

        dispatch(changePlayerOrder(reorder(players, source.index, destination.index)))
    }

    return (reorderable && isHost && players.length > 1)
        ? (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={listId}>
                    {provided => (
                        <FlexList ref={provided.innerRef} {...provided.droppableProps}>
                            {players.map((player, index) => (
                                <Draggable
                                    key={player.id}
                                    draggableId={player.id}
                                    index={index}
                                    isDragDisabled={waiting}
                                >
                                    {(provided, snapshot) => (
                                        <FlexItem
                                            dragging={snapshot.isDragging}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <IconButton
                                                {...provided.dragHandleProps}
                                                icon="bars"
                                                className="has-text-grey-light"
                                            />
                                            <PlayerRow player={player}/>
                                        </FlexItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </FlexList>
                    )}
                </Droppable>
            </DragDropContext>
        ) : (
            <FlexList>
                {players.map(player => <FlexItem key={player.id}><PlayerRow player={player}/></FlexItem>)}
            </FlexList>
        );
}