import styled from "styled-components/macro";
import {Content} from "./Content";

export const StandardContent = styled(Content)`
    width: 100%;
    @media (min-width: 700px) {
        width: 700px;
    }
    @media (min-width: 1000px) {
        width: 900px;
    }
`