import React from 'react';
import {Feedback} from "../feedback/Feedback";
import {useLocation} from "react-router-dom";
import {Link} from "react-router-dom";
import {Header, useHeaderState} from "../layout/Header";

export const LobbyHeader = React.memo(({helpSection}) => {
    const {open, toggleNav, closeNav} = useHeaderState();
    const location = useLocation();

    return <Header
        open={open}
        closeNav={closeNav}
        toggleNav={toggleNav}
        theme="is-light"
        helpSection={helpSection}
    >
        <div className="navbar-start">
            <Feedback>
                {({toggleFeedback}) =>
                    <Link
                        to="/feedback"
                        onClick={(event) => {
                            event.preventDefault();
                            toggleFeedback();
                            closeNav();
                        }}
                        className="navbar-item"
                    >
                        Feedback
                    </Link>
                }
            </Feedback>
            <Link className="navbar-item" to="/hello" state={{from: location.pathname}}>Me</Link>
        </div>
    </Header>
});