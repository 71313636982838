import React from 'react';
import {GameButton} from "../../general/GameButton";
import {Beta} from "../../general/Beta";
import styled from "styled-components/macro";

const CluelessText = styled.span`
    line-height: 0;
    margin-top: -3px;
    letter-spacing: -2px;
`;

export const CluelessArtistButton = (props) =>
    <GameButton
        className="is-primary"
        {...props}
    >
        <CluelessText className="font-clueless">clueless artist</CluelessText>
        <Beta/>
    </GameButton>;

