import React from 'react';
import cn from 'classnames';
import {Icon} from "../../general/Icon";

export function Input(
    {
        isTouched,
        isValid,
        isErrored,
        iconLeft,
        className,
        controlClassName,
        ...rest
    }
) {
    return (
        <div className={cn('control', controlClassName, {
            'has-icons-left': iconLeft,
            'has-icons-right': isTouched
        })}>
            <input
                className={cn('input', {'is-success': isValid, 'is-danger': isErrored}, className)}
                {...rest}
            />
            {iconLeft && (
                <span className="icon is-small is-left">
                    {iconLeft}
                </span>
            )}
            {isTouched && (
                <span className="icon is-small is-right">
                    <Icon
                        icon={isValid ? 'check' : 'exclamation-triangle'}
                        className={isValid ? 'has-text-success' : 'has-text-danger'}/>
                </span>
            )}
        </div>
    );
}