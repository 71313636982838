import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import {getFilteredConfig, getStarted} from "selectors/lobbySelectors";
import {getQueuedPlayerCount} from "selectors/playersSelectors";
import {SpaceVertical} from "../../styled/Containers";
import {LobbyButtonsHost} from "./LobbyButtons";
import {gameEnd, gameStart} from "../../actions/game";

export function GameConfig(
    {
        minimumPlayers = 0,
        maximumPlayers = 10,
        recommendedPlayers = 0,
        initialValues = {},
        initialTouched = {},
        validationSchema,
        form,
    }
) {
    const [config, queuedPlayerCount, started] = useSelector(state => [
        getFilteredConfig(state),
        getQueuedPlayerCount(state),
        getStarted(state),
    ]);

    const dispatch = useDispatch();

    const combinedInitialValues = useMemo(() => ({
        ...initialValues,
        ...config
    }), [initialValues, config])

    return <Formik
        initialValues={combinedInitialValues}
        onSubmit={(values, {setSubmitting}) => {
            dispatch(gameStart(values));
            setSubmitting(false);
        }}
        validationSchema={validationSchema}
        initialTouched={initialTouched}
    >
        {({isSubmitting, errors}) => <Form>
            <SpaceVertical>
                {!started && form && <section>{form}</section>}
                {!started && <>
                    {queuedPlayerCount >= minimumPlayers && queuedPlayerCount < recommendedPlayers && (
                        <p className="has-text-centered has-text-weight-bold">
                            This game plays best with {recommendedPlayers}+ players!
                        </p>
                    )}
                    {queuedPlayerCount < minimumPlayers && (
                        <p className="has-text-centered has-text-weight-bold has-text-danger">
                            Must have {minimumPlayers}+ players to start!
                        </p>
                    )}
                    {queuedPlayerCount >= maximumPlayers && (
                        <p className="has-text-centered has-text-weight-bold has-text-danger">
                            This game supports a maximum of {maximumPlayers} players!
                        </p>
                    )}
                </>}
                {started && (
                    <p className="has-text-centered has-text-weight-bold has-text-danger">
                        You have started a game that you are not participating in.
                        You will need to end the game when it has concluded.
                    </p>
                )}
                <LobbyButtonsHost
                    minimumPlayers={minimumPlayers}
                    maximumPlayers={maximumPlayers}
                    gameEnd={() => dispatch(gameEnd())}
                    canStart={
                        !isSubmitting
                        && Object.keys(errors).length === 0
                        && queuedPlayerCount >= minimumPlayers
                        && queuedPlayerCount <= maximumPlayers
                    }
                />
            </SpaceVertical>
        </Form>}
    </Formik>;
}