import React from 'react';
import {HelpSection} from "../HelpSection";
import {Link} from "react-router-dom";
import {Icon} from "../../general/Icon";
import {Tip} from "../../general/Tip";
import {HelpLink} from "../HelpLink";
import {Cell, GridContainer, Row} from "../../general/Grid";
import {CrownIcon, DisconectedIcon, KickIcon, PromoteIcon} from "../../lobby";
import {CenterText} from "../../../styled/Containers";

const IconCell = props => <Cell width="20px" flex {...props}/>;
const NameCell = ({children, ...props}) =>
    <Cell width="60px" grow={10} flex {...props}>
        <CenterText>{children}</CenterText>
    </Cell>;
const DescriptionCell = ({children, ...props}) =>
    <Cell width="150px" align="left" grow={100} {...props}>
        <p>{children}</p>
    </Cell>;

export const GeneralHelp = () =>
    <HelpSection title="General" tableOfContents>
        <HelpSection title="How to Use Devious Games">
            <p>
                Devious Games is a webapp where you can play games with your friends. Each player just needs their own
                phone or computer. It works great if you're in the same room, or with the help of an group-chatting app,
                like <a href="https://zoom.us/" target="_blank" rel="noopener noreferrer">Zoom</a> or
                {' '}<a href="https://discord.com/" target="_blank" rel="noopener noreferrer">Discord</a>,
                you can play with anyone in the world!
            </p>

            <p>
                Devious Games does <b>not</b> have any kind of matchmaking or chat functionality. You'll need to supply
                the humans, as well as a way to talk to them.
            </p>
        </HelpSection>
        <HelpSection title="Lobby System">
            <p>
                A lobby is where you will group up with your friends to get ready to play a game. Each lobby has a
                randomly generated code which your friends will use to join.
            </p>
            <HelpSection title="Creating a Lobby">
                <p>
                    To create a lobby, visit the <Link to="/">Home page</Link> and click on the name of the game you
                    would like to play under "Create a new lobby"
                </p>
            </HelpSection>
            <HelpSection title="Inviting Friends">
                <p>Inviting friends to your lobby is easy and can be done one of two ways:</p>
                <ul>
                    <li>
                        Tell or send them the lobby code. They will then need to enter this code on the Home page where
                        it says "Join an existing lobby".
                    </li>
                    <li>
                        Send them a link to the lobby. If you're already using a chat app, the easiest way it to send
                        the link. To make this easy, the code shown in the lobby is a link that you can copy and send.
                        On mobile you can usually copy a link like this by holding your finger on it briefly. Once your
                        friend has received the code they can simply open it in a web browser, enter a name, and they
                        will be immediately placed in your lobby.
                    </li>
                </ul>
            </HelpSection>
            <HelpSection title="Lobby Icons">
                <p>
                    While in the lobby you may see the following icons next to various players names. Here are their
                    meanings:
                </p>
                <section>
                    <GridContainer>
                        <Row>
                            <IconCell><CrownIcon/></IconCell>
                            <NameCell>Lobby Leader</NameCell>
                            <DescriptionCell>
                                This player controls the lobby as described in the following section on
                                {' '}<HelpLink>Lobby Management</HelpLink>.
                            </DescriptionCell>
                        </Row>
                        <Row>
                            <IconCell><DisconectedIcon/></IconCell>
                            <NameCell>Link lost</NameCell>
                            <DescriptionCell>
                                This player has lost connection with the game. <b>Don't be alarmed!</b> This is
                                temporary and indicates that the player is not paying attention at the moment. As soon
                                as that player re-opens the lobby page, they will resume exactly where they left off.
                            </DescriptionCell>
                        </Row>
                        <Row>
                            <IconCell><PromoteIcon/></IconCell>
                            <NameCell>Promote player</NameCell>
                            <DescriptionCell>
                                Only the Lobby Leader will see this control, and can use it to choose a new Lobby
                                Leader.
                            </DescriptionCell>
                        </Row>
                        <Row>
                            <IconCell><KickIcon/></IconCell>
                            <NameCell>Kick player</NameCell>
                            <DescriptionCell>
                                Only the Lobby Leader will see this control, and can use it to remove players.
                            </DescriptionCell>
                        </Row>
                    </GridContainer>
                </section>
            </HelpSection>
            <HelpSection title="Lobby Management">
                <p>
                    The player who starts a lobby will be given special control over the games played and settings used
                    while playing. This player is known as the "Lobby Leader" and can be identified by the crown icon
                    {' '}<Icon icon="crown" className="has-text-warning"/> shown next to their name.
                </p>

                <div>
                    The Lobby Leader has exclusive access to the following lobby management features in the pre-game
                    lobby:
                    <ul>
                        <li>Starting/Stopping games.</li>
                        <li>Changing game options.</li>
                        <li>Switching which game is being played.</li>
                        <li>
                            Promoting other players to be Lobby Leader using the
                            {' '}<Icon icon="angle-double-up" className="has-text-warning"/> icon.
                        </li>
                        <li>
                            Kicking players from the lobby using the <Icon icon="times"
                                                                           className="has-text-danger"/> icon.
                        </li>
                    </ul>
                </div>

                <HelpSection title="Promoting a Player">
                    <p>
                        Promoting a player will make them the new Lobby Leader. If you are currently the Lobby Leader
                        and choose to do this, you will lose control over the lobby. Make sure you're promoting someone
                        responsible!
                    </p>
                    <Tip tipText="note">
                        <p className="has-text-weight-bold">
                            If the Lobby Leader leaves the lobby by using the "Leave" button, then another player will
                            automatically be promoted to be Lobby Leader.
                        </p>
                    </Tip>
                </HelpSection>
                <HelpSection title="Kicking a Player">
                    <p>
                        Often you will find that players stop playing without actually removing themselves from the
                        lobby. When this happens, you can kick that player.
                    </p>

                    <p>
                        Kicked players can request to rejoin the lobby if they remember the lobby code, or have a link
                        to the lobby. When this happens, the Lobby Leader will be prompted to either allow or deny that
                        player from rejoining.
                    </p>
                </HelpSection>
                <HelpSection title="Reordering Players">
                    <p>
                        In some games, you will see <Icon icon="bars"/> next to each player's name. If this is the case,
                        then this is a game where player order is relevant. Dragging from this icon allows the Lobby
                        Leader to change the order of players. For example, if you're sitting around a table you can put
                        players in clockwise order. This will ensure that turns pass from each player to the player on
                        their left.
                    </p>
                </HelpSection>
            </HelpSection>
            <HelpSection title="General Player Functions">
                <p>
                    Players other than the Lobby Leader have several things they can do while waiting for a game to
                    start.
                </p>

                <HelpSection title="Spectating and Participating">
                    <p>
                        If a player does not wish to participate in a particular game, they can choose to spectate by
                        clicking the "Spectate" button while in the pre-game lobby. Once a game is started, spectators
                        will not receive any special access to what's happening in a game. This is intentional - Devious
                        Games are best played in the same room as your friends, and in this situation a spectator can
                        choose to spectate in person. Each player can then decide what information they want to give
                        away to a spectator.
                    </p>

                    <p>
                        When a spectator wants to rejoin, they can click "Participate". If a game is currently in
                        progress then the player will be placed in a queue called "Waiting to Join", and will be able to
                        join the next game.
                    </p>
                </HelpSection>
                <HelpSection title="Leaving the Lobby">
                    <p>
                        When a player is done playing, they can leave the lobby. Leaving is different from spectating -
                        spectating is intended to be temporary, while leaving the lobby should be done when the player
                        does not plan to return.
                    </p>
                </HelpSection>
            </HelpSection>
        </HelpSection>
    </HelpSection>;