import React from 'react';
import {Checkbox} from "./Checkbox";

export function GroupedCheckbox({selected, value, onChange, ...props}) {
    const handleChange = onChange && (
        (event) =>
            onChange(event.target.checked
                ? selected.concat(event.target.value)
                : selected.filter(currentValue => currentValue !== event.target.value)
            )
    );
    return <Checkbox
        checked={selected.includes(value)}
        value={value}
        onChange={handleChange}
        {...props}
    />;
}