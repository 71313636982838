import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearMessages, respondRejoinRequest} from "actions/lobby";
import {getIsHost, getMessages} from "selectors/lobbySelectors";
import {getPlayersRequestingRejoin} from "selectors/playersSelectors";
import {Notification} from "../general/Notification";
import nl2br from "react-nl2br";

export function LobbyNotifications() {
    const [playersRequestingRejoin, isHost, messages] = useSelector(state => [
        getPlayersRequestingRejoin(state),
        getIsHost(state),
        getMessages(state),
    ]);
    const dispatch = useDispatch();
    const allowRejoin = useCallback(
        (player, approved) => () => dispatch(respondRejoinRequest(player.id, approved)),
        [dispatch]
    );

    return <>
        {Object.entries(messages).map(([messageType, messages]) => (
            messages.length > 0 && (
                <Notification
                    key={messageType}
                    onClose={() => dispatch(clearMessages(messageType))}
                    className={`is-${messageType}`}
                >
                    {messages.map((message, i) => <p key={i}>{nl2br(message)}</p>)}
                </Notification>
            )
        ))}
        {isHost && playersRequestingRejoin.length > 0 && playersRequestingRejoin.map(player => (
            <Notification className="is-info">
                <div className="columns is-mobile">
                    <div className="column">
                        {player.name} requested to rejoin. Allow it?
                    </div>
                    <div className="is-narrow has-text-right buttons has-addons">
                        <span className="button" onClick={allowRejoin(player, true)}>Yes</span>
                        <span className="button" onClick={allowRejoin(player, false)}>No</span>
                    </div>
                </div>
            </Notification>
        ))}
    </>;
}