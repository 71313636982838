import React from 'react';
import styled from 'styled-components/macro';

const TipContainer = styled.div`
    > ul {
        margin-top: 0 !important;
    }
`

const TipTitle = styled.p`
    text-align: left;
    font-size: 0.75rem;
    font-weight: bolder;
    margin: 0 !important;
    text-transform: uppercase;
`;

export const Tip = ({children, tipText = 'tip', onClose}) => <div>
    <TipContainer className="notification content">
        {onClose && <button className="delete" onClick={onClose}/>}
        <TipTitle>{tipText}:</TipTitle>
        {children}
    </TipContainer>
</div>;