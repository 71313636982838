import React from 'react';
import {GameButton} from "../../general/GameButton";
import styled from 'styled-components/macro';
import {Beta} from "../../general/Beta";

const AvalonText = styled.span`
    font-family: 'Berkshire Swash', cursive;
    font-weight: bold;
    margin-top: -2px;
`;

export const AvalonButton = (props) =>
    <GameButton
        className="is-info"
        {...props}
    >
        <AvalonText>Avalon</AvalonText>
        <Beta/>
    </GameButton>;

