import React, {useEffect} from 'react';
import {games} from 'components/games';
import io from "socket.io-client";
import {useDispatch, useSelector} from "react-redux";
import {KickedLobby} from "../lobby";
import {createTimesyncClient} from "../../libs/createTimesyncClient";
import {disconnect, newSocket, newTimesync, timesyncOffset} from "../../actions/play";
import {useNavigate, useParams} from "react-router-dom";
import {getUser} from "selectors/userSelectors";
import {getIsKicked} from "selectors/playersSelectors";
import {LobbyHeader} from "../lobby/LobbyHeader";
import {AltHeader} from "../layout/HeaderTeleporter";
import {CenteredLoading} from "../layout/CenteredLoading";
import {getTimeSyncReady} from "../../selectors/timesyncSelectors";

export const PlayPage = () => {
    const [game, loading, reconnecting, timesyncReady, isKicked, userPlayer] = useSelector(state => [
        state.handshake.game,
        state.handshake.loading,
        state.handshake.reconnecting,
        getTimeSyncReady(state),
        getIsKicked(state),
        getUser(state),
    ]);
    const {code} = useParams();

    const rawDispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const socket = io({
            query: {...userPlayer, code},
            forceNew: true,
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: Infinity,
        });

        const dispatch = process.env.NODE_ENV === 'development'
            ? (...args) => {
                rawDispatch.apply(null, args);
                console.log.apply(null, args);
            }
            : rawDispatch;

        const ts = createTimesyncClient({
            socket,
            onOffsetChange: offset => dispatch(timesyncOffset(offset))
        });

        dispatch(newSocket(socket));
        dispatch(newTimesync(ts));
        socket.on('play', dispatch);
        socket.on('refresh-game', () => socket.emit('handshake-refresh-game'));
        socket.on('leave', (props) => {
            dispatch({type: 'LEAVE'});
            if (props) {
                navigate('/transition', {
                    replace: true,
                    state: {
                        to: '/',
                        transition: {
                            titleClassName: 'has-text-danger',
                            ...props
                        }
                    }
                });
            } else {
                // noinspection JSIgnoredPromiseFromCall
                navigate('/', {replace: true});
            }
        });
        socket.on('disconnect', () => dispatch(disconnect()));
        return () => {
            socket.close();
            ts.destroy();
        };
    }, [code, rawDispatch, navigate, userPlayer]);

    if (isKicked) {
        return <KickedLobby/>;
    }

    return <div style={{userSelect: 'none'}}>
        <AltHeader>
            <LobbyHeader helpSection={game}/>
        </AltHeader>
        <React.Suspense fallback={false}>
            {!loading && timesyncReady && <div style={{display: reconnecting ? 'none' : 'block'}}>
                {React.createElement(games[game].Component || 'div', {code})}
            </div>}
            {(loading || reconnecting || !timesyncReady) && <CenteredLoading/>}
        </React.Suspense>
    </div>;
};