import React, {useContext} from 'react';
import {HelpTitle} from "./HelpTitle";
import {TableOfContents} from "./TableOfContents";
import {SpaceVertical} from "../../styled/Containers";
import {createHelpHash} from "./createHelpHash";
import {initialList} from "../../styled/mixins";
import styled from 'styled-components/macro';

export const HelpContext = React.createContext({level: 0, section: undefined});

const tierColors = [
    '#89C4D4',
    '#8ABF71',
    '#e3965b',
    '#f0d862',
    '#D971A1'
];

const HelpSectionStyled = styled.div`
    padding-left: 1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    
    border-left: 4px solid ${props => props.borderColor};
    border-top: 4px solid ${props => props.borderColor};
    border-radius: 10px 4px 0;

    @media (max-width: 1024px) {
        padding-left: 1rem;
    }
    @media (max-width: 500px) {
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    
    ${initialList}
`

export const HelpSection = ({title, link = title, children, tableOfContents = false}) => {
    const {level, section} = useContext(HelpContext);

    return <section>
        <HelpTitle size={30 - (level * 5)} id={createHelpHash(section, link)}>{title}</HelpTitle>
        <HelpSectionStyled borderColor={tierColors[level]}>
            <HelpContext.Provider value={{level: level + 1, section: tableOfContents ? title : section}}>
                <SpaceVertical>
                    {tableOfContents && <TableOfContents children={children}/>}
                    {children}
                </SpaceVertical>
            </HelpContext.Provider>
        </HelpSectionStyled>
    </section>;
};