import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {leaveLobby} from "actions/lobby";
import {ConfirmButton} from "../general/ConfirmButton";

export function LeaveButton() {
    const waiting = useSelector(state => state.lobby.waiting);
    const dispatch = useDispatch();

    return (
        <ConfirmButton
            confirmText="You Sure?"
            onClick={() => dispatch(leaveLobby())}
            type="button"
            className="button is-danger"
            disabled={waiting}
        >
            Leave
        </ConfirmButton>
    );
}