import {createTeleporter} from "react-teleporter";
import React from "react";
import {HeaderStandard} from "./HeaderStandard";

const HeaderTeleporter = createTeleporter();

export const AppHeader = () => <>
    <HeaderTeleporter.Target/>
    <HeaderTeleporter.Source>
        <HeaderStandard/>
    </HeaderTeleporter.Source>
</>

export const AltHeader = HeaderTeleporter.Source