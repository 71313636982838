import React from "react";
import {Route, Routes} from "react-router-dom";
import {PlayerPage} from "./components/pages/PlayerPage";
import {FeedbackPage} from "./components/pages/FeedbackPage";
import {HelpPage} from "./components/pages/HelpPage";
import {AdminRoute, NameRequiredRoute} from "./components/ProtectedRoute";
import {GamesPage} from "./components/pages/GamesPage/GamesPage";
import {PlayPage} from "./components/pages/PlayPage";
import {AdminLoginPage} from "./components/pages/admin/AdminLoginPage";
import {LazyComponent} from "./components/general/LazyComponent";
import {NotFoundPage} from "./components/pages/NotFoundPage";
import {TransitionPage} from "./components/pages/TransitionPage";

export const AppRoutes = () =>
    <Routes>
        <Route path="/hello"><PlayerPage/></Route>
        <Route path="/about">
            <LazyComponent component={React.lazy(() => import('components/pages/AboutPage'))}/>
        </Route>
        <Route path="/changes">
            <LazyComponent component={React.lazy(() => import('components/pages/ChangesPage'))}/>
        </Route>
        <Route path="/feedback"><FeedbackPage/></Route>
        <Route path="/help"><HelpPage/></Route>
        <Route path="/transition"><TransitionPage/></Route>

        <NameRequiredRoute path="/" element={<GamesPage/>}/>
        <NameRequiredRoute path="/play/:code/*" element={<PlayPage/>}/>

        <Route path="/admin/login"><AdminLoginPage/></Route>
        <AdminRoute path="/admin/*">
            <LazyComponent component={React.lazy(() => import('components/pages/admin/AdminRoutes'))}/>
        </AdminRoute>

        <Route path="*" element={<NotFoundPage/>}/>
    </Routes>;