import emitAndWait from "libs/emitAndWait";
import {getSocket} from "../selectors/handshakeSelectors";

export const newSocket = socket => ({
    type: 'NEW_SOCKET',
    socket,
});
export const disconnect = () => ({
    type: 'DISCONNECTED'
});
export const startWaiting = () => (dispatch, getState) => {
    dispatch({
        type: 'START_WAITING',
        timer: setTimeout(() => {
            if (getState().lobby.waiting) {
                getSocket(getState()).emit('handshake-needs-initial');
            }
        }, 5000)
    });
};
export const changeGame = (game) => emitAndWait('handshake-change-game', game);

export const newTimesync = client => ({
    type: 'NEW_TIMESYNC',
    client
});
export const timesyncOffset = offset => ({
    type: 'TIMESYNC_OFFSET',
    offset
});