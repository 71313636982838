import React, {useCallback} from 'react';
import {useLocation, useMatch} from "react-router-dom";
import {Link, NavLink as BaseNavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {getIsAdmin, getUser} from "selectors/userSelectors";
import {Header, useHeaderState} from "./Header";

export const HeaderStandard = React.memo(() => {
    const [{name}, isAdmin] = useSelector(state => [
        getUser(state),
        getIsAdmin(state),
    ]);

    const {open, toggleNav, closeNav} = useHeaderState();
    const location = useLocation();
    const isHelpRoute = useMatch('/help');
    const isAdminRoute = useMatch('/admin/*');

    const NavLink = useCallback(
        ({className, ...props}) =>
            <BaseNavLink
                end
                className={className}
                activeClassName="active-page"
                onClick={closeNav}
                {...props}
            />,
        [closeNav]
    );

    return <>
        <Header open={open} closeNav={closeNav} toggleNav={toggleNav} helpActive={isHelpRoute}>
            <div className="navbar-start">
                <NavLink to="/" className="navbar-item">Home</NavLink>
                <NavLink to="/about" className="navbar-item">About</NavLink>
                <NavLink to="/changes" className="navbar-item">Changes</NavLink>
                <NavLink to="/feedback" className="navbar-item">Feedback</NavLink>
                {name
                    ? <NavLink
                        className="navbar-item"
                        to="/hello"
                        state={{from: location.pathname}}
                    >
                        Me
                    </NavLink>
                    : <NavLink className="navbar-item" to="/hello">
                        Hello
                    </NavLink>
                }

            </div>
            {isAdmin && <div className="navbar-end">
                <div className={`navbar-item has-dropdown is-hoverable ${isAdminRoute && "active-page"}`}>
                    <Link className="navbar-link" to="/admin">Admin</Link>
                    <div className="navbar-dropdown">
                        <NavLink className="navbar-item" to="/admin/lobbies">Lobbies</NavLink>
                        <NavLink className="navbar-item" to="/admin/users">Users</NavLink>
                        <NavLink className="navbar-item" to="/admin/feedback">Feedback</NavLink>
                        <NavLink className="navbar-item" to="/admin/logout">Logout</NavLink>
                    </div>
                </div>
            </div>}
        </Header>
    </>
});