import React from 'react';
import {Provider} from 'react-redux'
import {createStore} from './createStore';
import {AppRoutes} from "./AppRoutes";
import {BrowserRouter} from 'react-router-dom';
import {ApolloProvider} from "@apollo/client";
import {apolloClient} from "./apolloClient";
import {ContentContainer, PageWrapper} from "./components/layout/PageWrapper";
import {AppHeader} from "./components/layout/HeaderTeleporter"; //
import {Footer} from "./components/layout/Footer";
import {ThemeProvider} from "styled-components/macro";
import {defaultTheme} from "./styled/defaultTheme";
import {enablePatches} from 'immer';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import './App.scss';
import './iconLibrary';
import {PreFooterTeleporter} from "./components/layout/PreFooter";

dayjs.extend(duration);
enablePatches();
const store = createStore();

const App = () => (
    <ApolloProvider client={apolloClient}>
        <Provider store={store}>
            <ThemeProvider theme={defaultTheme}>
                <BrowserRouter>
                    <PageWrapper>
                        <ContentContainer>
                            <AppHeader/>
                            <AppRoutes/>
                        </ContentContainer>
                        <PreFooterTeleporter.Target/>
                        <Footer/>
                    </PageWrapper>
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    </ApolloProvider>
);

export default React.memo(App);