import React from 'react';
import {GameButton} from "../../general/GameButton";
import {Beta} from "../../general/Beta";
import styled from "styled-components/macro";

const HeistText = styled.span`
    font-family: 'Bebas Neue', Tahoma, sans-serif;
    font-size: 2rem;
    text-transform: uppercase;
    margin-top: 5px;   
`;

export const HeistButton = (props) =>
    <GameButton className="is-danger"{...props}>
        <HeistText>Heist</HeistText>
        <Beta/>
    </GameButton>;

