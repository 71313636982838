import React from 'react';
import {HelpSection} from "../HelpSection";
import {Tip} from "../../general/Tip";
import {Text} from "../../../styled/Typography";
import {GAMES} from "shared/constants/games";

export const HeistHelp = () =>
    <HelpSection title="Heist" link={GAMES.HEIST} tableOfContents>
        <HelpSection title="Overview">
            <p>
                Heist is a high stakes game of chicken. The game consists of several rounds (Heists) where the players
                gain money the longer they stay in the Heist. If a player stays too long they are caught by the
                authorities and lose all the money they gained. The trouble is, there's no way to precisely know when
                the cops are going to show up. Who has the guts to go for glory?!
            </p>
        </HelpSection>

        <HelpSection title="Game Flow">
            <HelpSection title="Planned Heists">
                <p>
                    When the game starts it will display the locations for all Heists planned during that
                    game. It's very important to consider the locations given. Some locations are more or less
                    profitable, and so it's a good idea to consider which rounds you can take bigger risks in.
                </p>
                <p>
                    This screen will also inform you if being caught results in spending an additional round in jail.
                </p>
            </HelpSection>

            <HelpSection title="The Heist">
                <p>
                    During the Heist you will constantly accumulate money as long as you stay in, and you freeze the
                    amount gained as soon as you run (by pressing the large red button).
                </p>
                <p>
                    As the Heist progresses, messages will pop up which give hints about what is happening in the Heist.
                    These are your only hint as to when the Heist might end!
                </p>
                <Tip>
                    Though the Heists may vary quite a bit from game to game, the Heists in a particular game are
                    guaranteed to be exactly the same for all players - including the messages displayed and when they
                    are displayed.
                </Tip>
            </HelpSection>

            <HelpSection title="Getting Caught">
                <p>
                    If you do not leave the Heist in time, then the police will show up and you will be caught. Being
                    caught causes you to forfeit all cash accumulated during that round, and if the jail rule is enabled
                    then you will also sit out of the following round. Getting caught virtually guarantees that you will
                    lose!
                </p>
            </HelpSection>

            <HelpSection title="Game End">
                <p>
                    Once all the rounds of the game have been played out, the player with the highest total cash wins.
                </p>
            </HelpSection>
        </HelpSection>

        <HelpSection title="Setup">
            <Text $bold>
                Only the Lobby Leader can control the following settings.
            </Text>
            <HelpSection title="Number of rounds">
                <p>
                    The game can be played anywhere from 1-9 rounds.
                </p>
            </HelpSection>

            <HelpSection title="Enabling Jail">
                <p>
                    Jail can be enabled by checking the box next to "Players spend a round in jail after being caught".
                    When enabled, players will lose both the cash from the Heist where they are caught as well as the
                    heist after that one.
                </p>
                <Tip>
                    Enabling this option makes the game much more punishing. It's better left off when first playing.
                </Tip>
            </HelpSection>
        </HelpSection>
    </HelpSection>;