import React, {useContext} from 'react';
import {createHelpHash} from "./createHelpHash";
import {Link} from "react-router-dom";
import {HelpContext} from "./HelpSection";

export function HelpLink({children, section: propSection}) {
    const {section: contextSection} = useContext(HelpContext);
    const section = propSection || contextSection;
    return <Link to={{hash: '#' + createHelpHash(section, children)}}>
        {children}
    </Link>;
}