import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faAngleDoubleUp,
    faArrowUp,
    faBars,
    faCheck,
    faCrown,
    faExclamationTriangle,
    faPaintBrush,
    faQuestion,
    faQuestionCircle,
    faRedoAlt, faRunning,
    faSearchMinus,
    faSearchPlus,
    faSkullCrossbones,
    faTimes,
    faUndoAlt,
    faUnlink,
    faUser,
    faUsers,
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faAngleDoubleUp,
    faBars,
    faCheck,
    faCrown,
    faExclamationTriangle,
    faQuestionCircle,
    faTimes,
    faUnlink,
    faUser,
    faUsers,
    faArrowUp,
    faQuestion,

    // For Avalon / DeadLast
    faSkullCrossbones,

    // For DeadLast
    // faCat, faDove, faDog, faDragon, faFrog, faSpider, faBug, faChessKing, faDiceD20, faFeatherAlt, faFireAlt,
    // faGhost, faHatWizard, faUserSecret, faTree, faPoo, faPaw,

    // For Drawy
    faPaintBrush, faSearchPlus, faSearchMinus, faUndoAlt, faRedoAlt,

    // For Heist
    faRunning,
);
