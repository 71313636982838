export const randomItem = (arr) => arr[Math.floor(Math.random() * arr.length)];
/**
 * @param arr
 * @param n
 * @returns {[]}
 */
export const randomItems = (arr, n) => {
    const result = new Array(n);
    let len = arr.length;
    const taken = new Array(len);
    if (n > len)
        n = len;
    while (n--) {
        const x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
};
export const shuffle = (arr) => {
    return arr
        .map(a => [Math.random(), a])
        .sort((a, b) => a[0] - b[0])
        .map(a => a[1]);
};
export const roundRobin = (arr) => {
    let index = 0;
    return () => {
        if (index >= arr.length) {
            index = 0;
        }
        return arr[index++];
    };
};