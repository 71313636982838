import React, {useCallback, useState} from 'react';

export const notificationType = {
    SUCCESS: 'is-success',
    WARNING: 'is-warning',
    DANGER: 'is-danger',
    INFO: 'is-info',
};

const closedNotification = {isOpen: false};

/**
 * Options for showing a dialog.
 * @typedef {Object} NotificationOptions
 * @property {string} [type]
 * @property {string} message
 * @property {boolean} [closable]
 */

/**
 * @callback NotificationOpen
 * @param {NotificationOptions} options
 */

/**
 * Object returned from useNotification
 * @typedef {Object} Notification
 * @property {boolean} isOpen
 * @property {Object} props
 * @property render
 * @property {NotificationOpen} open
 * @property {function} close
 */

/**
 * @param {NotificationOptions} [initialOptions]
 * @return {Notification}
 */
export function useNotification(initialOptions) {
    const optionsToNotification = ({type = notificationType.DANGER, message, closable = false}) => ({
        isOpen: true,
        props: {
            className: type,
            children: message,
            ...(closable && {onClose: close}),
        }
    });

    const initialNotification = initialOptions
        ? optionsToNotification(initialOptions)
        : closedNotification;

    const [notification, setNotification] = useState(initialNotification);
    const close = useCallback(
        () => setNotification(closedNotification),
        []
    );

    const open = useCallback(
        ({type, message, closable = false}) => {
            setNotification({
                isOpen: true,
                props: {
                    className: type,
                    children: message,
                    ...(closable && {onClose: close}),
                }
            })
        },
        [close]
    );

    const render = notification.isOpen && <Notification {...notification.props}/>;

    return {
        isOpen: notification.isOpen,
        props: notification.props,
        render,
        open,
        close
    };
}

export const Notification = ({className = notificationType.DANGER, onClose, children}) =>
    <section>
        <div className={`notification ${className}`}>
            {onClose && <button className="delete" onClick={onClose}/>}
            {children}
        </div>
    </section>;