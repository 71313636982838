export const defaultTheme = {
    colors: {
        link: '#3273dc',
        info: '#3298dc',
        success: '#48c774',
        offBlack: '#363636',
        danger: '#f14668',
    },
    fontSize: {
        xs: '0.5rem',
        sm: '0.75rem',
        md: '1rem',
        lg: '1.25rem',
        xl: '1.5rem',
    },
}